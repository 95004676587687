import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../../styles/teacher/createTeacher.css";

const UpdateTeacher = () => {
  const { id, adminId } = useParams(); // Extract teacher ID and admin ID from URL params
  // Get API URL and API Key from environment variables
  const API_URL = process.env.REACT_APP_API_URL;
  const API_KEY = process.env.REACT_APP_API_KEY;
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    password: "",
    role: "",
    gender: "",
    age: "",
    state: "",
    city: "",
    levelName: "",
    gradeName: "",
    subjectNames: [], // Initialize as an array
    groupStudentsNumber: [""],
    dateOfJoin: "",
    bills: "",
    inbox: "",
    driveLink: "",
  });
  const [error, setError] = useState("");
  const [levels, setLevels] = useState([]);
  const [grades, setGrades] = useState([]);
  const [subjects, setSubjects] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchTeacherData = async () => {
      try {
        // Fetch teacher data using API_URL and API_KEY
        const response = await axios.get(`${API_URL}/teachers/${id}`, {
          headers: {
            "API-Key": API_KEY, // Pass the API Key in the headers
          },
        });

        // Update formData with the fetched teacher data
        setFormData((prevData) => ({
          ...prevData,
          ...response.data,
          inbox: response.data.inbox || "", // Ensure inbox is populated from the API
          bills: response.data.bills || "", // Ensure bills is populated from the API
          groupStudentsNumber: Array.isArray(response.data.groupStudentsNumber)
            ? response.data.groupStudentsNumber
            : [response.data.groupStudentsNumber],
        }));
      } catch (error) {
        console.error("Error fetching teacher data:", error);
        setError("Failed to fetch teacher data. Please try again.");
      }
    };

    const fetchOptions = async () => {
      try {
        // Fetch levels, grades, and subjects using API_URL and API_KEY
        const levelResponse = await axios.get(`${API_URL}/levels`, {
          headers: {
            "API-Key": API_KEY,
          },
        });
        const gradeResponse = await axios.get(`${API_URL}/grades`, {
          headers: {
            "API-Key": API_KEY,
          },
        });
        const subjectResponse = await axios.get(`${API_URL}/subjects`, {
          headers: {
            "API-Key": API_KEY,
          },
        });

        setLevels(levelResponse.data);
        setGrades(gradeResponse.data);
        setSubjects(subjectResponse.data);
      } catch (error) {
        console.error("Error fetching options:", error);
        setError("Failed to load options. Please try again.");
      }
    };

    fetchTeacherData();
    fetchOptions();
  }, [id]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setFormData((prevData) => {
        const subjectNames = checked
          ? [...prevData.subjectNames, value]
          : prevData.subjectNames.filter((subject) => subject !== value);
        return { ...prevData, subjectNames };
      });
    } else if (name.startsWith("groupStudentsNumber")) {
      const index = parseInt(name.split("-")[1]);
      const newGroupStudentsNumber = [...formData.groupStudentsNumber];
      newGroupStudentsNumber[index] = value;
      setFormData({ ...formData, groupStudentsNumber: newGroupStudentsNumber });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleAddGroupStudentNumber = () => {
    setFormData((prevData) => ({
      ...prevData,
      groupStudentsNumber: [...prevData.groupStudentsNumber, ""],
    }));
  };

  const handleRemoveGroupStudentNumber = () => {
    if (formData.groupStudentsNumber.length > 1) {
      setFormData((prevData) => ({
        ...prevData,
        groupStudentsNumber: prevData.groupStudentsNumber.slice(0, -1),
      }));
    }
  };

  const handleSubjectChange = (subject) => {
    setFormData((prevData) => {
      const subjectNames = prevData.subjectNames.includes(subject)
        ? prevData.subjectNames.filter((s) => s !== subject)
        : [...prevData.subjectNames, subject];
      return { ...prevData, subjectNames };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.put(`${API_URL}/teachers/${id}`, formData, {
        headers: {
          "Content-Type": "application/json",
          "API-Key": API_KEY, // Pass the API Key for authentication
        },
      });

      // Navigate to the teacher list page after successful update
      navigate(`/teacher-list/${adminId}`);
    } catch (error) {
      console.error("Error updating teacher data:", error);

      // Handle and display errors
      if (error.response) {
        setError(
          error.response.data.message ||
            "Failed to update teacher data. Please try again."
        );
      } else {
        setError("Failed to update teacher data. Please try again.");
      }
    }
  };

  const handleCancel = () => {
    navigate(`/teacher-list/${adminId}`);
  };

  return (
    <div className="create-teacher-container">
      <h2>Update Teacher</h2>
      {error && <p className="error">{error}</p>}
      <form onSubmit={handleSubmit}>
        <div>
          <label>First Name</label>
          <input
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Last Name</label>
          <input
            type="text"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Password</label>
          <input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Role</label>
          <input
            type="text"
            name="role"
            value={formData.role}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Email</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Phone Number</label>
          <input
            type="text"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Gender</label>
          <select
            name="gender"
            value={formData.gender}
            onChange={handleChange}
            required
          >
            <option value="">Select Gender</option>
            <option value="M">Male</option>
            <option value="F">Female</option>
          </select>
        </div>
        <div>
          <label>Age</label>
          <input
            type="number"
            name="age"
            value={formData.age}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>State</label>
          <input
            type="text"
            name="state"
            value={formData.state}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>City</label>
          <input
            type="text"
            name="city"
            value={formData.city}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Inbox</label>
          <input
            type="text"
            name="inbox"
            value={formData.inbox}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Bills</label>
          <input
            type="text"
            name="bills"
            value={formData.bills}
            onChange={handleChange}
            required
          />
        </div>

        <div>
          <label>Drive Link</label>
          <input
            type="text"
            name="driveLink"
            value={formData.driveLink}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Level Name</label>
          <select
            name="levelName"
            value={formData.levelName}
            onChange={handleChange}
            required
          >
            <option value="">Select Level</option>
            {levels.map((level) => (
              <option key={level.id} value={level.name}>
                {level.name}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label>Grade Name</label>
          <select
            name="gradeName"
            value={formData.gradeName}
            onChange={handleChange}
            required
          >
            <option value="">Select Grade</option>
            {grades.map((grade) => (
              <option key={grade.id} value={grade.name}>
                {grade.name}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label>Subject Names</label>
          {subjects.map((subject) => (
            <div key={subject.id}>
              <label>
                <input
                  type="checkbox"
                  checked={formData.subjectNames.includes(subject.name)}
                  onChange={() => handleSubjectChange(subject.name)}
                />
                {subject.name}
              </label>
            </div>
          ))}
        </div>
        <div>
          <label>Date of Join</label>
          <input
            type="date"
            name="dateOfJoin"
            value={formData.dateOfJoin}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Bills</label>
          <input
            type="text"
            name="bills"
            value={formData.bills || ""}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Inbox</label>
          <input
            type="text"
            name="inbox"
            value={formData.inbox || ""}
            onChange={handleChange}
          />
        </div>
        <div style={styles.formGroup}>
          <label style={styles.label}>Group Students Number</label>
          {formData.groupStudentsNumber.map((number, index) => (
            <input
              key={index}
              type="number"
              name={`groupStudentsNumber-${index}`}
              value={number}
              onChange={handleChange}
              required
              min="0"
              style={styles.input}
            />
          ))}
          <button
            type="button"
            onClick={handleAddGroupStudentNumber}
            style={styles.addButton}
          >
            Add Another Group Number
          </button>
          {formData.groupStudentsNumber.length > 1 && (
            <button
              type="button"
              onClick={handleRemoveGroupStudentNumber}
              style={styles.cancelButton}
            >
              Cancel Last Group Number
            </button>
          )}
        </div>
        <button type="submit">Update Teacher</button>
        <button
          type="button"
          onClick={handleCancel}
          style={{ marginLeft: "10px" }}
        >
          Cancel
        </button>
      </form>
    </div>
  );
};

// Styles remain unchanged
const styles = {
  container: {
    maxWidth: "600px",
    margin: "auto",
    padding: "20px",
    border: "1px solid #ccc",
    borderRadius: "5px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  formGroup: {
    marginBottom: "15px",
  },
  label: {
    marginBottom: "5px",
    fontWeight: "bold",
  },
  input: {
    padding: "10px",
    fontSize: "16px",
    border: "1px solid #ccc",
    borderRadius: "4px",
  },
  select: {
    padding: "10px",
    fontSize: "16px",
    border: "1px solid #ccc",
    borderRadius: "4px",
  },
  button: {
    padding: "10px",
    fontSize: "16px",
    backgroundColor: "#007BFF",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
  addButton: {
    padding: "5px",
    marginTop: "5px",
    backgroundColor: "#28A745",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
  cancelButton: {
    padding: "5px",
    marginTop: "5px",
    backgroundColor: "#dc3545",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
  error: {
    color: "red",
  },
};

export default UpdateTeacher;
