import React from "react";
import TeacherProfile from "../components/Teacher/TeacherProfile";

const TeacherDashboard = () => {
  const teacherId = localStorage.getItem("teacherId"); // Assuming teacherId is stored in local storage

  return (
    <div className="teacher-dashboard">
      <TeacherProfile teacherId={teacherId} />
    </div>
  );
};

export default TeacherDashboard;
