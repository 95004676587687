// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.video-list-container {
  padding: 20px;
  max-width: 800px;
  margin: auto;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

h3 {
  text-align: center;
  color: #333;
}

.add-video-button {
  margin: 20px 0;
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-video-button:hover {
  background-color: #0056b3;
}

.video-table {
  width: 100%;
  border-collapse: collapse;
}

.video-table th,
.video-table td {
  padding: 12px;
  border: 1px solid #ddd;
  text-align: left;
}

.video-table th {
  background-color: #007bff;
  color: white;
}

.video-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.video-table tr:hover {
  background-color: #f1f1f1;
}

.video-table button {
  margin-right: 5px;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  transition: background-color 0.3s ease;
}

.video-table button:hover {
  background-color: #218838;
}
`, "",{"version":3,"sources":["webpack://./src/styles/quiz/video.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,gBAAgB;EAChB,YAAY;EACZ,yBAAyB;EACzB,kBAAkB;EAClB,wCAAwC;AAC1C;;AAEA;EACE,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,sCAAsC;AACxC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,WAAW;EACX,yBAAyB;AAC3B;;AAEA;;EAEE,aAAa;EACb,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,yBAAyB;EACzB,YAAY;EACZ,sCAAsC;AACxC;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".video-list-container {\n  padding: 20px;\n  max-width: 800px;\n  margin: auto;\n  background-color: #f9f9f9;\n  border-radius: 8px;\n  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);\n}\n\nh3 {\n  text-align: center;\n  color: #333;\n}\n\n.add-video-button {\n  margin: 20px 0;\n  padding: 10px 15px;\n  background-color: #007bff;\n  color: white;\n  border: none;\n  border-radius: 5px;\n  cursor: pointer;\n  transition: background-color 0.3s ease;\n}\n\n.add-video-button:hover {\n  background-color: #0056b3;\n}\n\n.video-table {\n  width: 100%;\n  border-collapse: collapse;\n}\n\n.video-table th,\n.video-table td {\n  padding: 12px;\n  border: 1px solid #ddd;\n  text-align: left;\n}\n\n.video-table th {\n  background-color: #007bff;\n  color: white;\n}\n\n.video-table tr:nth-child(even) {\n  background-color: #f2f2f2;\n}\n\n.video-table tr:hover {\n  background-color: #f1f1f1;\n}\n\n.video-table button {\n  margin-right: 5px;\n  padding: 5px 10px;\n  border: none;\n  border-radius: 5px;\n  cursor: pointer;\n  background-color: #007bff;\n  color: white;\n  transition: background-color 0.3s ease;\n}\n\n.video-table button:hover {\n  background-color: #218838;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
