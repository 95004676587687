import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../../styles/quiz/quizlist.css";

// Define your API URL and API Key (either hardcode them here or import them from a config file)

const API_URL = process.env.REACT_APP_API_URL; // Your API URL
const API_KEY = process.env.REACT_APP_API_KEY; // From .env file

const QuizList = () => {
  const { courseId } = useParams();
  const [quizzes, setQuizzes] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchQuizzes = async () => {
      try {
        if (courseId) {
          // Adding API URL and API Key in headers
          const response = await axios.get(
            `${API_URL}/quizzes/courses/${courseId}`,
            {
              headers: { "API-Key": API_KEY },
            }
          );
          setQuizzes(response.data);
        }
      } catch (error) {
        console.error("Error fetching quizzes:", error);
      }
    };

    fetchQuizzes();
  }, [courseId]);

  const handleAddQuiz = () => {
    navigate(`/add-quiz/${courseId}`); // Redirect to add quiz page
  };

  const handleUpdateQuiz = (quizId) => {
    navigate(`/update-quiz/${quizId}`); // Redirect to update quiz page
  };

  const handleDeleteQuiz = async (quizId) => {
    try {
      // Adding API URL and API Key in headers for delete request
      await axios.delete(`${API_URL}/quizzes/${quizId}`, {
        headers: { "API-Key": API_KEY },
      });
      setQuizzes(quizzes.filter((quiz) => quiz.id !== quizId)); // Update state
      alert("Quiz deleted successfully.");
    } catch (error) {
      console.error("Error deleting quiz:", error);
      alert("Failed to delete quiz.");
    }
  };

  return (
    <div className="quiz-list-container">
      <h3>Quiz List</h3>
      <button className="add-quiz-button" onClick={handleAddQuiz}>
        Add Quiz
      </button>
      {quizzes.length > 0 ? (
        <table className="quiz-table">
          <thead>
            <tr>
              <th>Quiz Name</th>
              <th>Link</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {quizzes.map((quiz) => (
              <tr key={quiz.id}>
                <td>{quiz.quizName}</td>
                <td>
                  <a
                    href={quiz.quizLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Open Quiz
                  </a>
                </td>
                <td>
                  <button onClick={() => handleUpdateQuiz(quiz.id)}>
                    Update
                  </button>
                  <button onClick={() => handleDeleteQuiz(quiz.id)}>
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No quizzes available.</p>
      )}
    </div>
  );
};

export default QuizList;
