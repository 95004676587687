import React from "react";
import AdminProfile from "../components/Admin/AdminProfile";

const AdminDashboard = () => {
  const adminId = localStorage.getItem("adminId"); // Retrieve admin ID from local storage
  console.log("Admin ID from localStorage:", adminId); // Log the admin ID

  return (
    <div>
      <AdminProfile id={adminId} /> {/* Pass admin ID to AdminProfile */}
    </div>
  );
};

export default AdminDashboard;
