import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // useNavigate in React Router v6

const CreateGrade = () => {
  const [name, setName] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate(); // useNavigate hook for navigation
  const API_URL = process.env.REACT_APP_API_URL;
  const API_KEY = process.env.REACT_APP_API_KEY;
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!name) {
      setError("Grade name is required");
      return;
    }

    try {
      await axios.post(
        `${API_URL}/grades`,
        { name },
        {
          headers: {
            "API-Key": API_KEY,
          },
        }
      );
      navigate("/admin-list"); // Redirect after successful creation
    } catch (error) {
      setError("Failed to create grade. Please try again.");
    }
  };

  return (
    <div className="create-grade-container">
      <h2>Create New Grade</h2>
      {error && <p className="error-message">{error}</p>}
      <form onSubmit={handleSubmit}>
        <div>
          <label>Grade Name:</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="submit-button">
          Create Grade
        </button>
      </form>
    </div>
  );
};

export default CreateGrade;
