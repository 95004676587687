import React from "react";
import { createRoot } from "react-dom/client"; // Import createRoot

import "./index.css"; // Include your CSS styles here

import AppRoutes from "./routes"; // Updated import

const container = document.getElementById("root");
const root = createRoot(container); // Create a root

<script src="https://accounts.google.com/gsi/client" async defer></script>;

root.render(
  <React.StrictMode>
    <AppRoutes />
  </React.StrictMode>
);
