import React from "react";
import Home from "../components/common/Home";

const HomePage = () => {
  return (
    <div className="home-page">
      <Home />
    </div>
  );
};

export default HomePage;
