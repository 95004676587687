import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const UpdateStudentByTeacher = ({ inbox, bills }) => {
  const { id, adminId } = useParams(); // Extract student ID and admin ID from URL params
  // Get API URL and API Key from environment variables
  const API_URL = process.env.REACT_APP_API_URL;
  const API_KEY = process.env.REACT_APP_API_KEY;
  const [formData, setFormData] = useState({
    inbox: inbox,
  });

  const [error, setError] = useState("");
  const [levels, setLevels] = useState([]);
  const [grades, setGrades] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const levelResponse = await axios.get(`${API_URL}/levels`, {
          headers: {
            "API-Key": API_KEY, // Add API key to the request headers
          },
        });
        const gradeResponse = await axios.get(`${API_URL}/grades`, {
          headers: {
            "API-Key": API_KEY, // Add API key to the request headers
          },
        });
        const subjectResponse = await axios.get(`${API_URL}/subjects`, {
          headers: {
            "API-Key": API_KEY, // Add API key to the request headers
          },
        });

        setLevels(levelResponse.data);
        setGrades(gradeResponse.data);
        setSubjects(subjectResponse.data);
      } catch (error) {
        setError("Failed to load options. Please try again.");
      }
    };

    const fetchStudentData = async () => {
      try {
        const response = await axios.get(`${API_URL}/students/${id}`, {
          headers: {
            "API-Key": API_KEY, // Add API key to the request headers
          },
        });
        setFormData((prevData) => ({
          ...prevData,
          ...response.data,
          inbox: response.data.inbox,
        }));
      } catch (error) {
        setError("Failed to fetch student data. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchOptions();
    fetchStudentData();
  }, [id]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setFormData((prevData) => {
        const subjectNames = checked
          ? [...prevData.subjectNames, value]
          : prevData.subjectNames.filter((subject) => subject !== value);
        return { ...prevData, subjectNames };
      });
    } else if (name.startsWith("groupStudentsNumber")) {
      const index = parseInt(name.split("-")[1]);
      const newGroupStudentsNumber = [...formData.groupStudentsNumber];
      newGroupStudentsNumber[index] = value;
      setFormData({ ...formData, groupStudentsNumber: newGroupStudentsNumber });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleAddGroupStudentNumber = () => {
    setFormData((prevData) => ({
      ...prevData,
      groupStudentsNumber: [...prevData.groupStudentsNumber, ""],
    }));
  };

  const handleRemoveGroupStudentNumber = () => {
    if (formData.groupStudentsNumber.length > 1) {
      setFormData((prevData) => ({
        ...prevData,
        groupStudentsNumber: prevData.groupStudentsNumber.slice(0, -1),
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.inbox) {
      setError("Please fill in all required fields.");
      return;
    }

    try {
      await axios.put(`${API_URL}/students/${id}`, formData, {
        headers: {
          "Content-Type": "application/json",
          "API-Key": API_KEY, // Add the API key to the request headers
        },
      });
      navigate("/teacher-dashboard"); // Navigate to teacher dashboard after successful update
    } catch (error) {
      console.error("Error updating student data:", error);
      if (error.response) {
        setError(
          error.response.data.message ||
            "Failed to update student data. Please try again."
        );
      } else {
        setError("Failed to update student data. Please try again.");
      }
    }
  };

  const handleCancel = () => {
    navigate("/teacher-dashboard"); // Navigate to student list on cancel
  };

  if (isLoading) {
    return <p>Loading...</p>;
  }

  return (
    <div style={styles.container}>
      <h2>Connect Student</h2>
      {error && <p style={styles.error}>{error}</p>}
      <form onSubmit={handleSubmit} style={styles.form}>
        {/* Form Fields */}

        {/* Inbox */}
        <div style={styles.formGroup}>
          <label style={styles.label}>Inbox</label>
          <textarea
            type="text"
            name="inbox"
            value={formData.inbox}
            onChange={handleChange}
            style={{ width: "100%", height: "100px", padding: "10px" }}
          />
        </div>

        {/* Submit Button */}
        <button type="submit" style={styles.button}>
          Send
        </button>

        {/* Cancel Button */}
        <button
          type="button"
          onClick={handleCancel}
          style={styles.cancelButton}
        >
          Cancel
        </button>
      </form>
    </div>
  );
};

// Styles remain unchanged
const styles = {
  container: {
    maxWidth: "600px",
    margin: "auto",
    padding: "20px",
    border: "1px solid #ccc",
    borderRadius: "5px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  formGroup: {
    marginBottom: "15px",
  },
  label: {
    marginBottom: "5px",
    fontWeight: "bold",
  },
  input: {
    padding: "10px",
    fontSize: "16px",
    border: "1px solid #ccc",
    borderRadius: "4px",
  },
  select: {
    padding: "10px",
    fontSize: "16px",
    border: "1px solid #ccc",
    borderRadius: "4px",
  },
  button: {
    padding: "10px",
    fontSize: "16px",
    backgroundColor: "#007BFF",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
  addButton: {
    padding: "5px",
    marginTop: "5px",
    backgroundColor: "#28A745",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
  cancelButton: {
    padding: "5px",
    marginTop: "5px",
    backgroundColor: "#dc3545",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
  error: {
    color: "red",
  },
};

export default UpdateStudentByTeacher;
