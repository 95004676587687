import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom"; // useNavigate and useParams in React Router v6

const UpdateGrade = () => {
  const { id } = useParams(); // Get the grade id from the URL
  const [grade, setGrade] = useState({ name: "" });
  const [error, setError] = useState("");
  const navigate = useNavigate(); // useNavigate hook for navigation

  const API_URL = process.env.REACT_APP_API_URL; // API URL from environment variables
  const API_KEY = process.env.REACT_APP_API_KEY; // API Key from environment variables

  useEffect(() => {
    const fetchGrade = async () => {
      try {
        const response = await axios.get(`${API_URL}/grades/${id}`, {
          headers: { "API-Key": API_KEY }, // Adding API key in request headers
        });
        setGrade(response.data);
      } catch (error) {
        setError("Failed to fetch grade data.");
      }
    };

    fetchGrade();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!grade.name) {
      setError("Grade name is required");
      return;
    }

    try {
      await axios.put(
        `${API_URL}/grades/${id}`,
        { name: grade.name },
        {
          headers: { "API-Key": API_KEY }, // Adding API key in request headers
        }
      );
      navigate("/admin-list"); // Redirect after successful update
    } catch (error) {
      setError("Failed to update grade. Please try again.");
    }
  };

  return (
    <div className="update-grade-container">
      <h2>Update Grade</h2>
      {error && <p className="error-message">{error}</p>}
      <form onSubmit={handleSubmit}>
        <div>
          <label>Grade Name:</label>
          <input
            type="text"
            value={grade.name}
            onChange={(e) => setGrade({ ...grade, name: e.target.value })}
            required
          />
        </div>
        <button type="submit" className="submit-button">
          Update Grade
        </button>
      </form>
    </div>
  );
};

export default UpdateGrade;
