import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const CreateStudent = () => {
  const { adminId } = useParams(); // Get adminId from route parameters
  // Get API URL and API Key from environment variables
  const API_URL = process.env.REACT_APP_API_URL;
  const API_KEY = process.env.REACT_APP_API_KEY;
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    password: "",
    role: "student",
    email: "",
    phoneNumber: "",
    gender: "",
    age: "",
    state: "",
    city: "",
    levelName: "",
    gradeName: "",
    subjectNames: [],
    dateOfJoin: "",
    bills: "",
    inbox: "",
    media: "",
  });

  const [groupStudentsNumber, setGroupStudentsNumber] = useState([""]);
  const [error, setError] = useState("");
  const [levels, setLevels] = useState([]);
  const [grades, setGrades] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchOptions = async () => {
      try {
        // Fetch levels data
        const levelResponse = await axios.get(`${API_URL}/levels`, {
          headers: {
            "API-Key": API_KEY, // Include the API Key in the headers
          },
        });

        // Fetch grades data
        const gradeResponse = await axios.get(`${API_URL}/grades`, {
          headers: {
            "API-Key": API_KEY, // Include the API Key in the headers
          },
        });

        // Fetch subjects data
        const subjectResponse = await axios.get(`${API_URL}/subjects`, {
          headers: {
            "API-Key": API_KEY, // Include the API Key in the headers
          },
        });

        // Update state with fetched data
        setLevels(levelResponse.data);
        setGrades(gradeResponse.data);
        setSubjects(subjectResponse.data);
      } catch (error) {
        console.error("Error fetching options:", error);
        setError("Failed to load options. Please try again.");
      }
    };

    fetchOptions();
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setFormData((prevData) => {
        const subjectNames = checked
          ? [...prevData.subjectNames, value]
          : prevData.subjectNames.filter((subject) => subject !== value);
        return { ...prevData, subjectNames };
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleGroupNumberChange = (index, value) => {
    const newGroupNumbers = [...groupStudentsNumber];
    newGroupNumbers[index] = value;
    setGroupStudentsNumber(newGroupNumbers);
  };

  const addGroupNumberField = () => {
    setGroupStudentsNumber([...groupStudentsNumber, ""]);
  };

  const removeGroupNumberField = () => {
    if (groupStudentsNumber.length > 1) {
      const newGroupNumbers = groupStudentsNumber.slice(0, -1);
      setGroupStudentsNumber(newGroupNumbers);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !formData.firstName ||
      !formData.lastName ||
      !formData.password ||
      !formData.email ||
      !formData.phoneNumber ||
      !formData.gender ||
      !formData.age ||
      !formData.state ||
      !formData.city ||
      !formData.levelName ||
      !formData.gradeName ||
      formData.subjectNames.length === 0 ||
      !formData.dateOfJoin ||
      !formData.inbox ||
      !formData.bills ||
      !formData.media ||
      groupStudentsNumber.some((num) => num === "")
    ) {
      setError("Please fill in all required fields.");
      return;
    }

    const formattedData = {
      ...formData,
      age: parseInt(formData.age, 10),
      groupStudentsNumber: groupStudentsNumber.map((num) => parseInt(num, 10)),
      dateOfJoin: new Date(formData.dateOfJoin).toISOString().split("T")[0],
    };

    try {
      await axios.post(`${API_URL}/students/admin/${adminId}`, formattedData, {
        headers: {
          "Content-Type": "application/json",
          "API-Key": API_KEY, // Include the API Key in the headers
        },
      });
      navigate(`/student-list/${adminId}`); // Navigate to student list after successful creation
    } catch (error) {
      console.error("Error adding student:", error);
      if (error.response) {
        setError(
          error.response.data.message ||
            "Failed to add student. Please try again."
        );
      } else {
        setError("Failed to add student. Please try again.");
      }
    }
  };

  return (
    <div style={styles.container}>
      <h2>Add Student</h2>
      {error && <p style={styles.error}>{error}</p>}
      <form onSubmit={handleSubmit} style={styles.form}>
        {/* First Name */}
        <div style={styles.formGroup}>
          <label style={styles.label}>First Name</label>
          <input
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            required
            style={styles.input}
          />
        </div>

        {/* Last Name */}
        <div style={styles.formGroup}>
          <label style={styles.label}>Last Name</label>
          <input
            type="text"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            required
            style={styles.input}
          />
        </div>

        {/* Password */}
        <div style={styles.formGroup}>
          <label style={styles.label}>Password</label>
          <input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            required
            style={styles.input}
          />
        </div>

        {/* Email */}
        <div style={styles.formGroup}>
          <label style={styles.label}>Email</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            style={styles.input}
          />
        </div>

        {/* Phone Number */}
        <div style={styles.formGroup}>
          <label style={styles.label}>Phone Number</label>
          <input
            type="text"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
            required
            style={styles.input}
          />
        </div>

        {/* Gender */}
        <div style={styles.formGroup}>
          <label style={styles.label}>Gender</label>
          <select
            name="gender"
            value={formData.gender}
            onChange={handleChange}
            required
            style={styles.select}
          >
            <option value="">Select Gender</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </select>
        </div>

        {/* Age */}
        <div style={styles.formGroup}>
          <label style={styles.label}>Age</label>
          <input
            type="number"
            name="age"
            value={formData.age}
            onChange={handleChange}
            required
            min="1"
            style={styles.input}
          />
        </div>

        {/* State */}
        <div style={styles.formGroup}>
          <label style={styles.label}>State</label>
          <input
            type="text"
            name="state"
            value={formData.state}
            onChange={handleChange}
            required
            style={styles.input}
          />
        </div>

        {/* City */}
        <div style={styles.formGroup}>
          <label style={styles.label}>City</label>
          <input
            type="text"
            name="city"
            value={formData.city}
            onChange={handleChange}
            required
            style={styles.input}
          />
        </div>

        {/* Level Name */}
        <div style={styles.formGroup}>
          <label style={styles.label}>Level Name</label>
          <select
            name="levelName"
            value={formData.levelName}
            onChange={handleChange}
            required
            style={styles.select}
          >
            <option value="">Select Level</option>
            {levels.map((level) => (
              <option key={level.id} value={level.name}>
                {level.name}
              </option>
            ))}
          </select>
        </div>

        {/* Grade Name */}
        <div style={styles.formGroup}>
          <label style={styles.label}>Grade Name</label>
          <select
            name="gradeName"
            value={formData.gradeName}
            onChange={handleChange}
            required
            style={styles.select}
          >
            <option value="">Select Grade</option>
            {grades.map((grade) => (
              <option key={grade.id} value={grade.name}>
                {grade.name}
              </option>
            ))}
          </select>
        </div>

        {/* Subjects */}
        <div style={styles.formGroup}>
          <label style={styles.label}>Subjects</label>
          {subjects.map((subject) => (
            <div key={subject.id}>
              <input
                type="checkbox"
                name="subjectNames"
                value={subject.name}
                checked={formData.subjectNames.includes(subject.name)}
                onChange={handleChange}
              />
              <label>{subject.name}</label>
            </div>
          ))}
        </div>

        {/* Group Students Numbers */}
        <div style={styles.formGroup}>
          <label style={styles.label}>Group Students Numbers</label>
          {groupStudentsNumber.map((number, index) => (
            <div key={index} style={styles.groupInput}>
              <input
                type="number"
                value={number}
                onChange={(e) => handleGroupNumberChange(index, e.target.value)}
                required
                style={styles.input}
              />
            </div>
          ))}
          <button
            type="button"
            onClick={addGroupNumberField}
            style={styles.addButton}
          >
            Add Group Number
          </button>
          {groupStudentsNumber.length > 1 && (
            <button
              type="button"
              onClick={removeGroupNumberField}
              style={styles.cancelButton}
            >
              Cancel Last
            </button>
          )}
        </div>

        {/* Date of Join */}
        <div style={styles.formGroup}>
          <label style={styles.label}>Date of Join</label>
          <input
            type="date"
            name="dateOfJoin"
            value={formData.dateOfJoin}
            onChange={handleChange}
            required
            style={styles.input}
          />
        </div>
        {/* Bills */}
        <div className="form-group">
          <label className="label">Bills</label>
          <input
            type="text"
            name="bills"
            value={formData.bills}
            onChange={handleChange}
            className="input"
          />
        </div>

        {/* Inbox */}
        <div className="form-group">
          <label className="label">Inbox</label>
          <input
            type="text"
            name="inbox"
            value={formData.inbox}
            onChange={handleChange}
            className="input"
          />
        </div>

        {/* social media */}
        <div className="form-group">
          <label className="label">Teacher social media</label>
          <input
            type="text"
            name="media"
            value={formData.media}
            onChange={handleChange}
            className="input"
          />
        </div>

        {/* Submit Button */}
        <button type="submit" style={styles.button}>
          Add Student
        </button>
      </form>
    </div>
  );
};

// Basic inline styles
const styles = {
  container: {
    maxWidth: "600px",
    margin: "0 auto",
    padding: "20px",
    backgroundColor: "#f9f9f9",
    borderRadius: "8px",
    boxShadow: "0 0 10px rgba(0,0,0,0.1)",
  },
  error: {
    color: "red",
    marginBottom: "10px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  formGroup: {
    marginBottom: "15px",
    display: "flex",
    flexDirection: "column",
  },
  groupInput: {
    display: "flex",
    alignItems: "center",
  },
  label: {
    marginBottom: "5px",
    fontWeight: "bold",
  },
  input: {
    padding: "8px",
    borderRadius: "4px",
    border: "1px solid #ccc",
  },
  select: {
    padding: "8px",
    borderRadius: "4px",
    border: "1px solid #ccc",
    backgroundColor: "white",
  },
  button: {
    padding: "10px",
    borderRadius: "4px",
    border: "none",
    backgroundColor: "purple",
    color: "white",
    fontWeight: "bold",
    cursor: "pointer",
  },
  addButton: {
    marginTop: "10px",
    padding: "10px",
    borderRadius: "4px",
    border: "none",
    backgroundColor: "green",
    color: "white",
    fontWeight: "bold",
    cursor: "pointer",
  },
  cancelButton: {
    marginTop: "10px",
    padding: "10px",
    borderRadius: "4px",
    border: "none",
    backgroundColor: "red",
    color: "white",
    fontWeight: "bold",
    cursor: "pointer",
  },
};

export default CreateStudent;
