import React from "react";
import "../../styles/home/home.css";

const GoogleFormEmbed = () => {
  return (
    <div className="google-form-section">
      <h1>Fill Out Our Form</h1>
      <iframe
        src="https://docs.google.com/forms/d/e/1FAIpQLSePiPelc3fLvQ8XZw3F8DwjVw2vELSYGtBJo0fsKS28bhbxLQ/viewform?embedded=true"
        width="640"
        height="1542"
        frameBorder="0"
        marginHeight="0"
        marginWidth="0"
        title="Google Form"
      >
        Loading…
      </iframe>
    </div>
  );
};

export default GoogleFormEmbed;
