// /pages/StudentDashboard.js
import React from "react";
import StudentProfile from "../components/Student/StudentProfile";

const StudentDashboard = () => {
  const studentId = localStorage.getItem("studentId"); // Assuming studentId is stored in local storage

  return (
    <div>
      <StudentProfile studentId={studentId} />
    </div>
  );
};

export default StudentDashboard;
