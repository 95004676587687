import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom"; // useNavigate and useParams in React Router v6

const UpdateLevel = () => {
  const { id } = useParams(); // Get the level id from the URL
  const [level, setLevel] = useState({ name: "" });
  const [error, setError] = useState("");
  const navigate = useNavigate(); // useNavigate hook for navigation

  const API_URL = process.env.REACT_APP_API_URL; // API URL from environment variables
  const API_KEY = process.env.REACT_APP_API_KEY; // API Key from environment variables

  useEffect(() => {
    const fetchLevel = async () => {
      try {
        const response = await axios.get(`${API_URL}/levels/${id}`, {
          headers: { "API-Key": API_KEY }, // Add API key in request headers
        });
        setLevel(response.data);
      } catch (error) {
        setError("Failed to fetch level data.");
      }
    };

    fetchLevel();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!level.name) {
      setError("Level name is required");
      return;
    }

    try {
      await axios.put(
        `${API_URL}/levels/${id}`,
        { name: level.name },
        {
          headers: { "API-Key": API_KEY }, // Add API key in request headers
        }
      );
      navigate("/admin-list"); // Redirect after successful update
    } catch (error) {
      setError("Failed to update level. Please try again.");
    }
  };

  return (
    <div className="update-level-container">
      <h2>Update Level</h2>
      {error && <p className="error-message">{error}</p>}
      <form onSubmit={handleSubmit}>
        <div>
          <label>Level Name:</label>
          <input
            type="text"
            value={level.name}
            onChange={(e) => setLevel({ ...level, name: e.target.value })}
            required
          />
        </div>
        <button type="submit" className="submit-button">
          Update Level
        </button>
      </form>
    </div>
  );
};

export default UpdateLevel;
