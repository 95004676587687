import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom"; // useNavigate and useParams in React Router v6

const UpdateSubject = () => {
  const { id } = useParams(); // Get the subject id from the URL
  const [subject, setSubject] = useState({ name: "" });
  const [error, setError] = useState("");
  const navigate = useNavigate(); // useNavigate hook for navigation

  const API_URL = process.env.REACT_APP_API_URL; // API URL from environment variables
  const API_KEY = process.env.REACT_APP_API_KEY; // API Key from environment variables

  useEffect(() => {
    const fetchSubject = async () => {
      try {
        const response = await axios.get(`${API_URL}/subjects/${id}`, {
          headers: { "API-Key": API_KEY }, // Add API key in request headers
        });
        setSubject(response.data);
      } catch (error) {
        setError("Failed to fetch subject data.");
      }
    };

    fetchSubject();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!subject.name) {
      setError("Subject name is required");
      return;
    }

    try {
      await axios.put(
        `${API_URL}/subjects/${id}`,
        { name: subject.name },
        {
          headers: { "API-Key": API_KEY }, // Add API key in request headers
        }
      );
      navigate("/admin-list"); // Redirect after successful update
    } catch (error) {
      setError("Failed to update subject. Please try again.");
    }
  };

  return (
    <div className="update-subject-container">
      <h2>Update Subject</h2>
      {error && <p className="error-message">{error}</p>}
      <form onSubmit={handleSubmit}>
        <div>
          <label>Subject Name:</label>
          <input
            type="text"
            value={subject.name}
            onChange={(e) => setSubject({ ...subject, name: e.target.value })}
            required
          />
        </div>
        <button type="submit" className="submit-button">
          Update Subject
        </button>
      </form>
    </div>
  );
};

export default UpdateSubject;
