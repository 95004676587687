import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const CourseDetails = () => {
  const { id } = useParams(); // Get course ID from the route
  const [course, setCourse] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true); // Get API URL and API Key from environment variables
  const API_URL = process.env.REACT_APP_API_URL;
  const API_KEY = process.env.REACT_APP_API_KEY;

  useEffect(() => {
    // Fetch course data from API
    const fetchCourseData = async () => {
      try {
        const response = await axios.get(`${API_URL}/courses/${id}`, {
          headers: {
            "API-Key": API_KEY, // Add the API key to the request headers
          },
        });
        setCourse(response.data);
        setLoading(false);
      } catch (error) {
        setError("Failed to fetch course details.");
        setLoading(false);
      }
    };

    fetchCourseData();
  }, [id]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  // Extract the nextCourseDate from the course object (you can implement logic to compute this if necessary)
  const nextCourseDate = course ? course.nextCourseDate : "N/A";

  return (
    <div>
      <h2>Course Details</h2>
      {course ? (
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Level Name</th>
              <th>Grade Name</th>
              <th>Subject Name</th>
              <th>Group Students Number</th>
              <th>Live Course Link</th>
              <th>Next Course Date</th>
              <th>Course Time</th>
            </tr>
          </thead>
          <tbody>
            <tr key={course.id}>
              <td>{course.id}</td>
              <td>{course.levelName}</td>
              <td>{course.gradeName}</td>
              <td>{course.subjectName}</td>
              <td>{course.groupStudentsNumber}</td>
              <td>
                <a
                  href={course.liveCourseLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {course.liveCourseLink ? "Join Live Course" : "N/A"}
                </a>
              </td>
              <td>{nextCourseDate}</td> {/* Display computed date */}
              <td>{course.courseTime}</td>
            </tr>
          </tbody>
        </table>
      ) : (
        <p>No course details available.</p>
      )}
    </div>
  );
};

export default CourseDetails;
