import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const AddVideo = () => {
  const { courseId } = useParams(); // Get courseId from URL parameters
  const navigate = useNavigate();
  const [videoName, setVideoName] = useState("");
  const [videoLink, setVideoLink] = useState("");
  const [levelName, setLevelName] = useState("");
  const [gradeName, setGradeName] = useState("");
  const [subjectName, setSubjectName] = useState("");
  const [groupStudentsNumber, setGroupStudentsNumber] = useState(0);
  const [levels, setLevels] = useState([]);
  const [grades, setGrades] = useState([]);
  const [subjects, setSubjects] = useState([]);

  // Get API URL and API Key from environment variables
  const API_URL = process.env.REACT_APP_API_URL;
  const API_KEY = process.env.REACT_APP_API_KEY;

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch levels, grades, and subjects
        const levelsResponse = await axios.get(`${API_URL}/levels`, {
          headers: { "API-Key": API_KEY },
        });
        const gradesResponse = await axios.get(`${API_URL}/grades`, {
          headers: { "API-Key": API_KEY },
        });
        const subjectsResponse = await axios.get(`${API_URL}/subjects`, {
          headers: { "API-Key": API_KEY },
        });

        // Fetch course details
        const courseResponse = await axios.get(
          `${API_URL}/courses/${courseId}`,
          {
            headers: { "API-Key": API_KEY },
          }
        );

        setLevels(levelsResponse.data);
        setGrades(gradesResponse.data);
        setSubjects(subjectsResponse.data);

        // Set course details to state
        const { levelName, gradeName, subjectName, groupStudentsNumber } =
          courseResponse.data;
        setLevelName(levelName);
        setGradeName(gradeName);
        setSubjectName(subjectName);
        setGroupStudentsNumber(groupStudentsNumber);
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    fetchData();
  }, [courseId, API_URL, API_KEY]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const videoData = {
      videoName,
      videoLink,
      levelName,
      gradeName,
      subjectName,
      groupStudentsNumber,
    };

    try {
      const response = await axios.post(
        `${API_URL}/videos/courses/${courseId}`,
        videoData,
        {
          headers: {
            "API-Key": API_KEY, // Add API Key to request headers
          },
        }
      );
      console.log("Video added:", response.data);
      navigate(`/videos/${courseId}`); // Navigate back to the video list
    } catch (error) {
      console.error("Error adding video", error);
    }
  };

  return (
    <div className="create-teacher-container">
      <form onSubmit={handleSubmit}>
        <h2>Add Video</h2>
        <div>
          <label>Video Name:</label>
          <input
            type="text"
            value={videoName}
            onChange={(e) => setVideoName(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Video Link:</label>
          <input
            type="text"
            value={videoLink}
            onChange={(e) => setVideoLink(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Level:</label>
          <select
            value={levelName}
            onChange={(e) => setLevelName(e.target.value)}
            required
          >
            <option value="">Select Level</option>
            {levels.map((level) => (
              <option key={level.id} value={level.name}>
                {level.name}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label>Grade:</label>
          <select
            value={gradeName}
            onChange={(e) => setGradeName(e.target.value)}
            required
          >
            <option value="">Select Grade</option>
            {grades.map((grade) => (
              <option key={grade.id} value={grade.name}>
                {grade.name}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label>Subject:</label>
          <select
            value={subjectName}
            onChange={(e) => setSubjectName(e.target.value)}
            required
          >
            <option value="">Select Subject</option>
            {subjects.map((subject) => (
              <option key={subject.id} value={subject.name}>
                {subject.name}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label>Group Students Number:</label>
          <input
            type="number"
            value={groupStudentsNumber}
            onChange={(e) => setGroupStudentsNumber(e.target.value)}
            required
          />
        </div>
        <button type="submit">Add Video</button>
      </form>
    </div>
  );
};

export default AddVideo;
