import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const AddQuiz = () => {
  const { courseId } = useParams(); // Get courseId from URL parameters
  const navigate = useNavigate();
  const [quizName, setQuizName] = useState("");
  const [quizLink, setQuizLink] = useState("");
  const [levelName, setLevelName] = useState("");
  const [gradeName, setGradeName] = useState("");
  const [subjectName, setSubjectName] = useState("");
  const [groupStudentsNumber, setGroupStudentsNumber] = useState(0);
  const [levels, setLevels] = useState([]);
  const [grades, setGrades] = useState([]);
  const [subjects, setSubjects] = useState([]);

  const API_URL = process.env.REACT_APP_API_URL; // Your API URL
  const API_KEY = process.env.REACT_APP_API_KEY; // From .env file

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch levels, grades, and subjects with API key in headers
        const levelsResponse = await axios.get(`${API_URL}/levels`, {
          headers: { "API-Key": API_KEY },
        });
        const gradesResponse = await axios.get(`${API_URL}/grades`, {
          headers: { "API-Key": API_KEY },
        });
        const subjectsResponse = await axios.get(`${API_URL}/subjects`, {
          headers: { "API-Key": API_KEY },
        });

        // Fetch course details with API key in headers
        const courseResponse = await axios.get(
          `${API_URL}/courses/${courseId}`,
          {
            headers: { "API-Key": API_KEY },
          }
        );

        setLevels(levelsResponse.data);
        setGrades(gradesResponse.data);
        setSubjects(subjectsResponse.data);

        // Set course details to state
        const { levelName, gradeName, subjectName, groupStudentsNumber } =
          courseResponse.data;
        setLevelName(levelName);
        setGradeName(gradeName);
        setSubjectName(subjectName);
        setGroupStudentsNumber(groupStudentsNumber);
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    fetchData();
  }, [courseId]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const quizData = {
      quizName,
      quizLink,
      levelName,
      gradeName,
      subjectName,
      groupStudentsNumber,
    };

    try {
      const response = await axios.post(
        `${API_URL}/quizzes/courses/${courseId}`,
        quizData,
        {
          headers: { "API-Key": API_KEY },
        }
      );
      console.log("Quiz added:", response.data);
      navigate(`/quizzes/${courseId}`); // Navigate back to the quiz list
    } catch (error) {
      console.error("Error adding quiz", error);
    }
  };

  return (
    <div className="create-teacher-container">
      <form onSubmit={handleSubmit}>
        <h2>Add Quiz</h2>
        <div>
          <label>Quiz Name:</label>
          <input
            type="text"
            value={quizName}
            onChange={(e) => setQuizName(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Quiz Link:</label>
          <input
            type="text"
            value={quizLink}
            onChange={(e) => setQuizLink(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Level:</label>
          <select
            value={levelName}
            onChange={(e) => setLevelName(e.target.value)}
            required
          >
            <option value="">Select Level</option>
            {levels.map((level) => (
              <option key={level.id} value={level.name}>
                {level.name}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label>Grade:</label>
          <select
            value={gradeName}
            onChange={(e) => setGradeName(e.target.value)}
            required
          >
            <option value="">Select Grade</option>
            {grades.map((grade) => (
              <option key={grade.id} value={grade.name}>
                {grade.name}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label>Subject:</label>
          <select
            value={subjectName}
            onChange={(e) => setSubjectName(e.target.value)}
            required
          >
            <option value="">Select Subject</option>
            {subjects.map((subject) => (
              <option key={subject.id} value={subject.name}>
                {subject.name}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label>Group Students Number:</label>
          <input
            type="number"
            value={groupStudentsNumber}
            onChange={(e) => setGroupStudentsNumber(e.target.value)}
            required
          />
        </div>
        <button type="submit">Add Quiz</button>
      </form>
    </div>
  );
};

// Basic inline styles (for your reference)
const styles = {
  container: {
    maxWidth: "600px",
    margin: "0 auto",
    padding: "20px",
    backgroundColor: "#f9f9f9",
    borderRadius: "8px",
    boxShadow: "0 0 10px rgba(0,0,0,0.1)",
  },
  error: {
    color: "red",
    marginBottom: "10px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  formGroup: {
    marginBottom: "15px",
    display: "flex",
    flexDirection: "column",
  },
  groupInput: {
    display: "flex",
    alignItems: "center",
  },
  label: {
    marginBottom: "5px",
    fontWeight: "bold",
  },
  input: {
    padding: "8px",
    borderRadius: "4px",
    border: "1px solid #ccc",
  },
  select: {
    padding: "8px",
    borderRadius: "4px",
    border: "1px solid #ccc",
    backgroundColor: "white",
  },
  button: {
    padding: "10px",
    borderRadius: "4px",
    border: "none",
    backgroundColor: "purple",
    color: "white",
    fontWeight: "bold",
    cursor: "pointer",
  },
  addButton: {
    marginTop: "10px",
    padding: "10px",
    borderRadius: "4px",
    border: "none",
    backgroundColor: "green",
    color: "white",
    fontWeight: "bold",
    cursor: "pointer",
  },
  cancelButton: {
    marginTop: "10px",
    padding: "10px",
    borderRadius: "4px",
    border: "none",
    backgroundColor: "red",
    color: "white",
    fontWeight: "bold",
    cursor: "pointer",
  },
};

export default AddQuiz;
