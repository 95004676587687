import {
  faTelegramPlane,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import logo1 from "../../images/logo1.png";
import Logout from "../../images/Logout.png";
import "../../styles/teacher/teacherprofile.css";
import AuthService from "../auth/AuthService";

const TeacherProfile = () => {
  const [teacherData, setTeacherData] = useState(null);
  const [coursesData, setCoursesData] = useState([]);
  const [studentsDataMap, setStudentsDataMap] = useState({}); // State for mapping students by group number
  const [activeTab, setActiveTab] = useState("info"); // Track the active tab
  const navigate = useNavigate();
  const { t, i18n } = useTranslation(); // Destructure t and i18n
  const API_URL = process.env.REACT_APP_API_URL;
  const API_KEY = process.env.REACT_APP_API_KEY;

  // Language switcher function
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    const fetchTeacherData = async () => {
      try {
        // Check if user is logged in
        const teacher = AuthService.getCurrentUser();
        if (!teacher) {
          navigate("/teacher-login"); // Redirect to login if not authenticated
          return;
        }

        // Set teacher data
        setTeacherData(teacher);

        // Fetch courses for the teacher
        const coursesResponse = await axios.get(
          `${API_URL}/courses/teacher/${teacher.id}`,
          {
            headers: {
              "API-Key": API_KEY,
            },
          }
        );
        const courses = coursesResponse.data;
        setCoursesData(courses);

        // Fetch students for each course
        const studentsPromises = courses.map(async (course) => {
          const { groupStudentsNumber, levelName, gradeName, subjectName } =
            course;

          try {
            const studentsResponse = await axios.get(
              `${API_URL}/students/by-group-level-grade-subject`,
              {
                params: {
                  groupStudentsNumber,
                  levelName,
                  gradeName,
                  subjectName,
                },
                headers: {
                  "API-Key": API_KEY,
                },
              }
            );
            return {
              groupNumber: groupStudentsNumber,
              students: studentsResponse.data,
            };
          } catch (err) {
            console.error("Error fetching students:", err);
            return null; // Return null for failed requests
          }
        });

        // Resolve all promises
        const studentsDataArray = await Promise.all(studentsPromises);

        // Map students by group number
        const studentsDataMap = studentsDataArray.reduce((acc, groupData) => {
          if (groupData) {
            acc[groupData.groupNumber] = groupData.students;
          }
          return acc;
        }, {});

        setStudentsDataMap(studentsDataMap);
      } catch (error) {
        console.error("Error fetching teacher data:", error);
      }
    };

    fetchTeacherData();
  }, [navigate]);

  const handleConnectClick = (adminId, studentId) => {
    // This will navigate to the update student page with adminId and studentId
    navigate(`/update-student-by-teacher/${adminId}/${studentId}`);
  };
  // Function to render teacher info
  const renderTeacherInfo = () => (
    <div>
      <h3>{t("Teacher Info")}</h3>
      {teacherData ? (
        <>
          <p>First Name: {teacherData.firstName}</p>
          <p>Last Name: {teacherData.lastName}</p>
          <p>Password: {teacherData.password}</p>
          <p>Email: {teacherData.email}</p>
          <p>Role: {teacherData.role}</p>
          <p>Gender: {teacherData.gender}</p>
          <p>Phone: {teacherData.phoneNumber}</p>
          <p>Age: {teacherData.age}</p>
          <p>State: {teacherData.state}</p>
          <p>City: {teacherData.city}</p>
          <p>Date of Join: {teacherData.dateOfJoin}</p>
          <p>Level Name: {teacherData.levelName}</p>
          <p>Grade Name: {teacherData.gradeName}</p>
          <p>Subject Name: {teacherData.subjectNames}</p>
          <p>Students Groups: {teacherData.groupStudentsNumber}</p>
        </>
      ) : (
        <p>Loading teacher info...</p>
      )}
    </div>
  );

  // Function to render courses list
  const renderCoursesList = () => (
    <div>
      <h2>{t("Courses")}</h2>

      {coursesData.length > 0 ? (
        coursesData.map((course) => (
          <div key={course.id} className="course-container">
            <h4>
              {course.subjectName} - {course.gradeName}
            </h4>
            <p>
              {t("Course Level")}: {course.levelName}
            </p>
            <p>
              {t("Group Number")}: {course.groupStudentsNumber}
            </p>
            <p>
              {t("Course Days")}: {course.courseDays.join(", ")}
            </p>
            <p>
              {t("Time")}: {course.courseTime}
            </p>
            <p>
              {t("Drive Link")}:
              <a
                href={course.driveLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                {course.driveLink ? "click drive link" : "N/A"}
              </a>
            </p>
            <p>
              {t("Course Live Link")}:
              <a
                href={course.liveCourseLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                {course.liveCourseLink ? "Join Live Course" : "N/A"}
              </a>
            </p>

            <div className="course-actions">
              <button
                onClick={() => navigate(`/quizzes/${course.id}`)}
                className="button1"
              >
                {t("Quizzes")}
              </button>
              <button
                onClick={() => navigate(`/summaries/${course.id}`)}
                className="button1"
              >
                {t("Summaries")}
              </button>
              <button
                onClick={() => navigate(`/exams/${course.id}`)}
                className="button1"
              >
                {t("Exams")}
              </button>
              <button
                onClick={() => navigate(`/videos/${course.id}`)}
                className="button1"
              >
                {t("Videos")}
              </button>
            </div>
          </div>
        ))
      ) : (
        <p>No courses available.</p>
      )}
    </div>
  );

  // Function to render teacher info
  const renderInboxList = () => (
    <div>
      <h3>{t("Inbox")}</h3>
      {teacherData ? (
        <>
          <p>{teacherData.inbox}</p>
        </>
      ) : (
        <p>Loading teacher info...</p>
      )}
    </div>
  );

  // Function to render teacher info
  const renderBillsList = () => (
    <div>
      <h3>{t("Bills")}</h3>
      {teacherData ? (
        <>
          <p>{teacherData.bills}</p>
        </>
      ) : (
        <p>Loading teacher info...</p>
      )}
    </div>
  );

  const renderContact = () => (
    <div>
      <h3>{t("Contact Information")}</h3>
      <ul>
        <li>
          <FontAwesomeIcon icon={faWhatsapp} style={{ marginRight: "8px" }} />

          <a
            href="https://wa.me/+17859176976"
            style={{ textDecoration: "none" }}
          >
            {" "}
            WhatsApp
          </a>
        </li>
        <li>
          <FontAwesomeIcon
            icon={faTelegramPlane}
            style={{ marginRight: "8px" }}
          />

          <a href="https://t.me/nourbrara" style={{ textDecoration: "none" }}>
            {" "}
            Telegram
          </a>
        </li>
        <li>
          Email: <a href="mailto:support@example.com">support@example.com</a>
        </li>
      </ul>
    </div>
  );

  const renderStudentsList = () => (
    <div>
      <h3>{t("Students List")}</h3>
      {coursesData.length > 0 ? (
        coursesData.map((course) => {
          const students = studentsDataMap[course.groupStudentsNumber] || [];
          return (
            <div key={course.groupStudentsNumber}>
              <h4>
                {course.subjectName} - Group Number:{" "}
                {course.groupStudentsNumber}
              </h4>
              {students.length > 0 ? (
                <table className="table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Email</th>
                      <th>Phone Number</th>
                      <th>Level Name</th>
                      <th>Grade Name</th>
                      <th>Action</th>{" "}
                      {/* Added an Action column for the button */}
                    </tr>
                  </thead>
                  <tbody>
                    {students.map((student, index) => (
                      <tr key={student.id}>
                        <td>{index + 1}</td> {/* Display the order number */}
                        <td>{student.firstName}</td>
                        <td>{student.lastName}</td>
                        <td>{student.email}</td>
                        <td>{student.phoneNumber}</td>
                        <td>{student.levelName}</td>
                        <td>{student.gradeName}</td>
                        <td>
                          {/* The Connect button that will navigate */}
                          <button
                            onClick={() =>
                              handleConnectClick(course.adminId, student.id)
                            }
                          >
                            Connect
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p>No students available for this group.</p>
              )}
            </div>
          );
        })
      ) : (
        <p>No courses available.</p>
      )}
    </div>
  );

  return (
    <div className="container">
      {/* Header desktop */}
      <header className="desktop-header">
        <div className="header-left" style={{ marginLeft: "0.5em" }}>
          <img src={logo1} className="menu-icon6" alt="Graduation Cap Icon" />{" "}
          <p style={{ marginTop: "21px" }}>DZSCHOOL.ONLINE</p>
        </div>
        <div className="header-right">
          {/* Language Selection Dropdown */}
          <select
            onChange={(e) => changeLanguage(e.target.value)}
            defaultValue="en"
            className="language-select"
            aria-label="Language Selection"
          >
            {["en", "ar", "fr"].map((lang) => (
              <option key={lang} value={lang.toLowerCase()}>
                {lang.toUpperCase()}
              </option>
            ))}
          </select>

          {/* Logout Button */}
          <button
            className="logout-button"
            onClick={async () => {
              try {
                await AuthService.logout(); // Perform logout
                navigate("/home"); // Navigate to login page upon success
              } catch (error) {
                console.error("Logout failed:", error);
                // Optional: Show a message to the user if logout fails
              }
            }}
            aria-label="Sign Out"
          >
            <img src={Logout} className="menu-icon4" alt="Logout Icon" />
            <strong style={{ paddingRight: "4px" }}>{t("Sign out")}</strong>
          </button>
        </div>
      </header>
      <h2 style={{ textAlign: "center" }}>{t("Teacher Dashboard")}</h2>
      <div style={{ display: "flex", flex: 1 }}>
        <div className="sidebar" style={{ boxShadow: "none", border: "none" }}>
          <ul>
            <li onClick={() => setActiveTab("info")}>{t("Teacher Info")}</li>
            <li onClick={() => setActiveTab("courses")}>{t("Courses")}</li>
            <li onClick={() => setActiveTab("students")}>
              {" "}
              {t("Students List")}
            </li>
            <li onClick={() => setActiveTab("inbox")}>{t("Inbox")}</li>
            <li onClick={() => setActiveTab("bills")}>{t("Bills")}</li>
            <li onClick={() => setActiveTab("contact")}>{t("Contact")}</li>
          </ul>
        </div>

        <div className="content">
          {activeTab === "info" && renderTeacherInfo()}
          {activeTab === "courses" && renderCoursesList()}
          {activeTab === "students" && renderStudentsList()}
          {activeTab === "inbox" && renderInboxList()}
          {activeTab === "bills" && renderBillsList()}
          {activeTab === "contact" && renderContact()}
        </div>
      </div>
    </div>
  );
};

export default TeacherProfile;
