import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../../styles/course/AddCourse.css";

const AddCourse = () => {
  const { studentId } = useParams();
  const navigate = useNavigate();
  // Get API URL and API Key from environment variables
  const API_URL = process.env.REACT_APP_API_URL;
  const API_KEY = process.env.REACT_APP_API_KEY;

  const [courseData, setCourseData] = useState({
    levelName: "",
    gradeName: "",
    subjectName: "",
    teacherFirstName: "",
    teacherLastName: "",
    groupStudentsNumber: "", // This will now be a selected option
    liveCourseLink: "",
    courseDays: [], // Changed to an array for multiple selections
    courseTime: "",
  });

  const [levels, setLevels] = useState([]);
  const [grades, setGrades] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [groupStudentsOptions, setGroupStudentsOptions] = useState([]); // New state for group students options
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const [levelResponse, gradeResponse, subjectResponse] =
          await Promise.all([
            axios.get(`${API_URL}/levels`, {
              headers: { "API-Key": API_KEY }, // Add the API key to the request headers
            }),
            axios.get(`${API_URL}/grades`, {
              headers: { "API-Key": API_KEY }, // Add the API key to the request headers
            }),
            axios.get(`${API_URL}/subjects`, {
              headers: { "API-Key": API_KEY }, // Add the API key to the request headers
            }),
          ]);
        setLevels(levelResponse.data);
        setGrades(gradeResponse.data);
        setSubjects(subjectResponse.data);
      } catch (error) {
        setError("Failed to load options. Please try again.");
      }
    };

    const fetchStudentData = async () => {
      try {
        const response = await axios.get(`${API_URL}/students/${studentId}`, {
          headers: {
            "API-Key": API_KEY, // Add the API key to the request headers
          },
        });
        setGroupStudentsOptions(response.data.groupStudentsNumber || []); // Assume this comes as an array
      } catch (error) {
        setError("Failed to load student data. Please try again.");
      }
    };

    fetchOptions();
    fetchStudentData();
  }, [studentId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "courseDays") {
      const options = e.target.options;
      const selectedDays = [];
      for (let i = 0; i < options.length; i++) {
        if (options[i].selected) {
          selectedDays.push(options[i].value);
        }
      }
      setCourseData((prev) => ({ ...prev, courseDays: selectedDays }));
    } else {
      setCourseData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.post(`${API_URL}/courses/student/${studentId}`, courseData, {
        headers: {
          "API-Key": API_KEY, // Add the API key to the request headers
        },
      });
      navigate(`/course-list/${studentId}`);
    } catch (error) {
      setError("Failed to add course. Please try again.");
    }
  };

  return (
    <div className="add-course-container">
      <h2>Add Course</h2>
      {error && <p className="error-message">{error}</p>}
      <form onSubmit={handleSubmit} className="course-form">
        <div className="form-group">
          <label>Level Name:</label>
          <select
            name="levelName"
            value={courseData.levelName}
            onChange={handleChange}
            required
          >
            <option value="">Select Level</option>
            {levels.map((level) => (
              <option key={level.id} value={level.name}>
                {level.name}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label>Grade Name:</label>
          <select
            name="gradeName"
            value={courseData.gradeName}
            onChange={handleChange}
            required
          >
            <option value="">Select Grade</option>
            {grades.map((grade) => (
              <option key={grade.id} value={grade.name}>
                {grade.name}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label>Subject Name:</label>
          <select
            name="subjectName"
            value={courseData.subjectName}
            onChange={handleChange}
            required
          >
            <option value="">Select Subject</option>
            {subjects.map((subject) => (
              <option key={subject.id} value={subject.name}>
                {subject.name}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label>Teacher First Name:</label>
          <input
            type="text"
            name="teacherFirstName"
            value={courseData.teacherFirstName}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Teacher Last Name:</label>
          <input
            type="text"
            name="teacherLastName"
            value={courseData.teacherLastName}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Group Students Number:</label>
          <select
            name="groupStudentsNumber"
            value={courseData.groupStudentsNumber}
            onChange={handleChange}
            required
          >
            <option value="">Select Group Number</option>
            {groupStudentsOptions.map((number, index) => (
              <option key={index} value={number}>
                {number}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label>Live Course Link:</label>
          <input
            type="text"
            name="liveCourseLink"
            value={courseData.liveCourseLink}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Course Days:</label>
          <select
            name="courseDays"
            multiple
            value={courseData.courseDays}
            onChange={handleChange}
            required
          >
            <option value="Monday">Monday</option>
            <option value="Tuesday">Tuesday</option>
            <option value="Wednesday">Wednesday</option>
            <option value="Thursday">Thursday</option>
            <option value="Friday">Friday</option>
            <option value="Saturday">Saturday</option>
            <option value="Sunday">Sunday</option>
          </select>
        </div>
        <div className="form-group">
          <label>Course Time:</label>
          <input
            type="time"
            name="courseTime"
            value={courseData.courseTime}
            onChange={handleChange}
            required
          />
        </div>
        <button type="submit" className="submit-button">
          Add Course
        </button>
      </form>
    </div>
  );
};

export default AddCourse;
