import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import logo1 from "../../images/logo1.png";
import "../../styles/student/StudentList.css";
import AuthService from "../auth/AuthService";

const StudentList = () => {
  const { adminId } = useParams(); // Get adminId from route params
  const [students, setStudents] = useState([]);
  const [hierarchy, setHierarchy] = useState({});
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [selectedLevel, setSelectedLevel] = useState("");
  const [selectedGrade, setSelectedGrade] = useState("");
  const [error, setError] = useState("");

  const navigate = useNavigate();

  // Define your API URL and API Key securely using environment variables
  const API_URL = process.env.REACT_APP_API_URL; // Your API URL
  const API_KEY = process.env.REACT_APP_API_KEY; // From .env file

  // Fetch students based on adminId
  const fetchStudents = async () => {
    try {
      // Check if the admin is logged in
      const admin = AuthService.getCurrentUser();
      if (!admin) {
        // Redirect to admin login if not authenticated
        navigate("/admin-login");
        return;
      }

      if (!adminId) {
        setError("Admin ID not found. Please log in.");
        return;
      }

      const headers = {
        "API-Key": API_KEY, // Passing API key in the header
      };

      const response = await axios.get(`${API_URL}/students/admin/${adminId}`, {
        headers,
      });
      setStudents(response.data);
      organizeStudents(response.data);
    } catch (error) {
      setError("Failed to fetch students");
    }
  };

  // Organize students data into a hierarchy based on Level, Grade, and Subject
  const organizeStudents = (students) => {
    const hierarchy = {};

    students.forEach((student) => {
      const { levelName, gradeName, subjectNames } = student;

      if (!hierarchy[levelName]) {
        hierarchy[levelName] = {};
      }

      if (!hierarchy[levelName][gradeName]) {
        hierarchy[levelName][gradeName] = {};
      }

      subjectNames.forEach((subject) => {
        if (!hierarchy[levelName][gradeName][subject]) {
          hierarchy[levelName][gradeName][subject] = [];
        }
        hierarchy[levelName][gradeName][subject].push(student);
      });
    });

    setHierarchy(hierarchy);
  };

  useEffect(() => {
    fetchStudents(); // Fetch students when the component mounts or adminId changes
  }, [adminId, navigate]); // Ensure to run when adminId or navigate changes

  // Handle click on a subject to filter students based on level, grade, and subject
  const handleSubjectClick = (levelName, gradeName, subjectName) => {
    const studentsForSubject = hierarchy[levelName][gradeName][subjectName];
    setSelectedStudents(studentsForSubject || []);
    setSelectedLevel(levelName);
    setSelectedGrade(gradeName);
  };

  // Handle delete student action
  const handleDelete = async (id) => {
    try {
      await axios.delete(`${API_URL}/students/${id}`, {
        headers: {
          "API-Key": API_KEY, // Include the API Key in the headers
        },
      });
      fetchStudents(); // Refresh the list after deletion
    } catch (error) {
      console.error("Error deleting student:", error);
      setError("Failed to delete student. Please try again.");
    }
  };

  // Navigate to student details page
  const handleDetails = (id) => {
    navigate(`/student-details/${id}`);
  };

  // Navigate to update student page
  const handleUpdate = (id) => {
    navigate(`/update-student/${adminId}/${id}`); // Pass adminId and student ID
  };

  // Navigate to create new student page
  const handleAddStudent = () => {
    navigate(`/create-student/${adminId}`);
  };

  // Navigate to student courses page
  const handleCourses = (id) => {
    navigate(`/course-list/${id}`);
  };
  return (
    <div>
      <header className="desktop-header">
        <div className="header-left" style={{ marginLeft: "0.5em" }}>
          <img src={logo1} className="menu-icon6" alt="Graduation Cap Icon" />
          <p style={{ marginTop: "21px" }}>DZSCHOOL.ONLINE</p>
        </div>
        <div className="header-right"></div>
      </header>
      <div style={{ display: "flex" }}>
        {/* Sidebar with level, grade, and subject navigation */}
        <aside
          style={{
            width: "20%",
            padding: "10px",
            borderRight: "1px solid #ccc",
          }}
        >
          <h3>Menu</h3>
          {Object.entries(hierarchy).map(([levelName, grades]) => (
            <div key={levelName}>
              <h4>{levelName}</h4>
              {Object.entries(grades).map(([gradeName, subjects]) => (
                <div key={gradeName}>
                  <h5>{gradeName}</h5>
                  {Object.keys(subjects).map((subjectName) => (
                    <div key={subjectName}>
                      <button
                        className="button1"
                        onClick={() =>
                          handleSubjectClick(levelName, gradeName, subjectName)
                        }
                      >
                        {subjectName}
                      </button>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          ))}
        </aside>

        {/* Main Content: List of Students */}
        <main style={{ flex: 1, padding: "10px" }}>
          <h2>Students List</h2>
          {error && <p className="error">{error}</p>}
          <button onClick={handleAddStudent} className="button1">
            Add Student
          </button>
          <h3>
            {selectedLevel && selectedGrade
              ? `Selected Level: ${selectedLevel}, Grade: ${selectedGrade}`
              : "All Students"}
          </h3>
          <table className="table">
            <thead>
              <tr>
                <th>#</th> {/* Add a column for the order */}
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Password</th>
                <th>Level Name</th>
                <th>Grade Name</th>
                <th>Subject Names</th>
                <th>Group Students Number</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {(selectedStudents.length > 0 ? selectedStudents : students).map(
                (student, index) => (
                  <tr key={student.id}>
                    <td>{index + 1}</td> {/* Display the order number */}
                    <td>{student.firstName}</td>
                    <td>{student.lastName}</td>
                    <td>{student.email}</td>
                    <td>{student.password}</td>
                    <td>{student.levelName}</td>
                    <td>{student.gradeName}</td>
                    <td>{student.subjectNames.join(", ")}</td>
                    <td>
                      {Array.isArray(student.groupStudentsNumber)
                        ? student.groupStudentsNumber.join(", ")
                        : student.groupStudentsNumber}
                    </td>
                    <td>
                      <button
                        onClick={() => handleDetails(student.id)}
                        className="button1"
                      >
                        Details
                      </button>
                      <button
                        onClick={() => handleUpdate(student.id)}
                        className="button1"
                      >
                        Update
                      </button>
                      <button
                        onClick={() => handleDelete(student.id)}
                        className="button1"
                      >
                        Delete
                      </button>
                      <button
                        onClick={() => handleCourses(student.id)}
                        className="button1"
                      >
                        Courses
                      </button>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>

          {selectedStudents.length === 0 && students.length === 0 && (
            <p>No students available.</p>
          )}
        </main>
      </div>
    </div>
  );
};

export default StudentList;
