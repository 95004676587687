import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

// Admin Components
import AdminList from "./components/Admin/AdminList";
import CreateAdmin from "./components/Admin/CreateAdmin";
import Login from "./components/Admin/Login";
import UpdateAdmin from "./components/Admin/UpdateAdmin";

// Course Components
import AddCourse from "./components/Course/AddCourse";
import AddCourseToTeacher from "./components/Course/AddCourseToTeacher";
import CourseDetails from "./components/Course/CourseDetails";
import CourseList from "./components/Course/CourseList";
import CourseTeacherList from "./components/Course/CourseTeacherList";
import UpdateCourse from "./components/Course/UpdateCourse";
import UpdateCourseTeacher from "./components/Course/UpdateCourseTeacher";

// Quiz Components
import AddQuiz from "./components/Quiz/AddQuiz";
import QuizList from "./components/Quiz/QuizList";

// Exam Components
import AddExam from "./components/Exam/AddExam";
import ExamList from "./components/Exam/ExamList";

// Summary Components
import AddSummary from "./components/Summary/AddSummary";
import SummaryList from "./components/Summary/SummaryList";

// Video Components
import AddVideo from "./components/Video/AddVideo";
import VideoList from "./components/Video/VideoList";

// Student Components
import CreateStudent from "./components/Student/CreateStudent";
import StudentDetails from "./components/Student/StudentDetails";
import StudentList from "./components/Student/StudentList";
import UpdateStudent from "./components/Student/UpdateStudent";
import UpdateStudentByTeacher from "./components/Student/UpdateStudentByTeacher";

// Teacher Components
import CreateTeacher from "./components/Teacher/CreateTeacher";
import TeacherDetails from "./components/Teacher/TeacherDetails";
import TeacherList from "./components/Teacher/TeacherList";
import UpdateTeacher from "./components/Teacher/UpdateTeacher";

// Auth Components
import AdminLoginForm from "./components/auth/AdminLoginForm";
import StudentLoginForm from "./components/auth/StudentLoginForm";
import TeacherLoginForm from "./components/auth/TeacherLoginForm";

// Dashboard Pages
import AdminDashboard from "./pages/AdminDashboard";
import Home from "./pages/HomePage"; // Updated path for Home component
import StudentDashboard from "./pages/StudentDashboard";
import TeacherDashboard from "./pages/TeacherDashboard";

// Level, Grade, and Subject Management Components
import CreateGrade from "./components/LevelGradeSubject/CreateGrade";
import CreateLevel from "./components/LevelGradeSubject/CreateLevel";
import CreateSubject from "./components/LevelGradeSubject/CreateSubject";
import UpdateGrade from "./components/LevelGradeSubject/UpdateGrade";
import UpdateLevel from "./components/LevelGradeSubject/UpdateLevel";
import UpdateSubject from "./components/LevelGradeSubject/UpdateSubject";

// Sign-Up / Google Form Embed Component
import GoogleFormEmbed from "./components/Register/GoogleFormEmbed"; // Import GoogleFormEmbed

const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        {/* Home Page */}
        <Route path="/" element={<Home />} />{" "}
        <Route path="/home" element={<Home />} />
        {/* adminlist login */}
        <Route path="/login" element={<Login />} />
        {/* Sign-Up Route (Google Form) */}
        <Route path="/signup" element={<GoogleFormEmbed />} />{" "}
        {/* Sign-up page */}
        {/* Auth Routes */}
        <Route path="/admin-login" element={<AdminLoginForm />} />
        <Route path="/student-login" element={<StudentLoginForm />} />
        <Route path="/teacher-login" element={<TeacherLoginForm />} />
        {/* Dashboard Routes */}
        <Route path="/admin-dashboard" element={<AdminDashboard />} />
        <Route path="/student-dashboard" element={<StudentDashboard />} />
        <Route path="/teacher-dashboard" element={<TeacherDashboard />} />
        {/* Admin Management Routes */}
        <Route path="/admin-list" element={<AdminList />} />
        <Route path="/create-admin" element={<CreateAdmin />} />
        <Route path="/update-admin/:id" element={<UpdateAdmin />} />
        {/* Student Management Routes */}
        <Route path="/student-list/:adminId" element={<StudentList />} />
        <Route path="/create-student/:adminId" element={<CreateStudent />} />
        <Route
          path="/update-student/:adminId/:id"
          element={<UpdateStudent />}
        />
        <Route
          path="/update-student-by-teacher/:adminId/:id"
          element={<UpdateStudentByTeacher />}
        />
        <Route path="/student-details/:id" element={<StudentDetails />} />
        {/* Teacher Management Routes */}
        <Route path="/teacher-list/:adminId" element={<TeacherList />} />
        <Route path="/create-teacher/:adminId" element={<CreateTeacher />} />
        <Route
          path="/update-teacher/:adminId/:id"
          element={<UpdateTeacher />}
        />
        <Route path="/teacher-details/:id" element={<TeacherDetails />} />
        {/* Course Management Routes */}
        <Route path="/course-list/:studentId" element={<CourseList />} />
        <Route
          path="/course-teacher-list/:teacherId"
          element={<CourseTeacherList />}
        />
        <Route path="/add-course/:studentId" element={<AddCourse />} />
        <Route
          path="/add-course-to-teacher/:teacherId"
          element={<AddCourseToTeacher />}
        />
        <Route path="/course-details/:id" element={<CourseDetails />} />
        <Route
          path="/update-course/:studentId/:id"
          element={<UpdateCourse />}
        />
        <Route
          path="/update-course-teacher/:teacherId/:id"
          element={<UpdateCourseTeacher />}
        />
        {/* Quiz Management Routes */}
        <Route path="/quizzes/:courseId" element={<QuizList />} />
        <Route path="/add-quiz/:courseId" element={<AddQuiz />} />
        {/* Video Management Routes */}
        <Route path="/videos/:courseId" element={<VideoList />} />
        <Route path="/add-video/:courseId" element={<AddVideo />} />
        {/* Exam Management Routes */}
        <Route path="/exams/:courseId" element={<ExamList />} />
        <Route path="/add-exam/:courseId" element={<AddExam />} />
        {/* Summary Management Routes */}
        <Route path="/summaries/:courseId" element={<SummaryList />} />
        <Route path="/add-summary/:courseId" element={<AddSummary />} />
        {/* Level Management Routes */}
        <Route path="/create-level" element={<CreateLevel />} />
        <Route path="/update-level/:id" element={<UpdateLevel />} />
        {/* Subject Management Routes */}
        <Route path="/create-subject" element={<CreateSubject />} />
        <Route path="/update-subject/:id" element={<UpdateSubject />} />
        {/* Grade Management Routes */}
        <Route path="/create-grade" element={<CreateGrade />} />
        <Route path="/update-grade/:id" element={<UpdateGrade />} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
