import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const TeacherDetails = () => {
  const { id } = useParams(); // Get the teacher ID from route params
  const [teacher, setTeacher] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);

  // Get API URL and API Key from environment variables
  const API_URL = process.env.REACT_APP_API_URL;
  const API_KEY = process.env.REACT_APP_API_KEY;

  useEffect(() => {
    fetchTeacherDetails();
  }, [id]);

  const fetchTeacherDetails = async () => {
    try {
      const response = await axios.get(`${API_URL}/teachers/${id}`, {
        headers: {
          "API-Key": API_KEY, // Pass the API Key in headers
        },
      });

      setTeacher(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching teacher details:", error);

      // Update error state
      setError("Failed to fetch teacher details. Please try again.");
      setLoading(false);
    }
  };

  // Loading state
  if (loading) {
    return <p>Loading...</p>;
  }

  // Error state
  if (error) {
    return <p className="error">{error}</p>;
  }

  return (
    <div style={{ padding: "25px" }}>
      <h2>Teacher Details</h2>
      {teacher ? (
        <div>
          <p>
            <strong>First Name:</strong> {teacher.firstName}
          </p>
          <p>
            <strong>Last Name:</strong> {teacher.lastName}
          </p>
          <p>
            <strong>Phone Number:</strong> {teacher.phoneNumber}
          </p>
          <p>
            <strong>Email:</strong> {teacher.email}
          </p>
          <p>
            <strong>Gender:</strong> {teacher.gender}
          </p>
          <p>
            <strong>Age:</strong> {teacher.age}
          </p>
          <p>
            <strong>State:</strong> {teacher.state}
          </p>
          <p>
            <strong>City:</strong> {teacher.city}
          </p>
          <p>
            <strong>Level Name:</strong> {teacher.levelName}
          </p>
          <p>
            <strong>Grade Name:</strong> {teacher.gradeName}
          </p>
          <p>
            <strong>Subject Names:</strong> {teacher.subjectNames.join(", ")}
          </p>
          <p>
            <strong>Group Students Number:</strong>{" "}
            {teacher.groupStudentsNumber.join(", ")}
          </p>
          <p>
            <strong>Date of Join:</strong> {teacher.dateOfJoin}
          </p>
          <p>
            <strong>Role:</strong> {teacher.role}
          </p>

          <p>
            <strong>Inbox:</strong> {teacher.inbox}
          </p>
          <p>
            <strong>Bills:</strong> {teacher.bills}
          </p>
        </div>
      ) : (
        <p>No teacher data found.</p>
      )}
    </div>
  );
};

export default TeacherDetails;
