import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../../styles/quiz/summary.css";

const SummaryList = () => {
  const { courseId } = useParams();
  const [summaries, setSummaries] = useState([]);
  const navigate = useNavigate();

  // Get API URL and API Key from environment variables
  const API_URL = process.env.REACT_APP_API_URL;
  const API_KEY = process.env.REACT_APP_API_KEY;

  useEffect(() => {
    const fetchSummaries = async () => {
      try {
        if (courseId) {
          const response = await axios.get(
            `${API_URL}/summaries/courses/${courseId}`,
            {
              headers: {
                "API-Key": API_KEY,
              },
            }
          );
          setSummaries(response.data);
        }
      } catch (error) {
        console.error("Error fetching summaries:", error);
      }
    };

    fetchSummaries();
  }, [courseId, API_URL, API_KEY]);

  const handleAddSummary = () => {
    navigate(`/add-summary/${courseId}`); // Redirect to add summary page
  };

  const handleUpdateSummary = (summaryId) => {
    navigate(`/update-summary/${summaryId}`); // Redirect to update summary page
  };

  const handleDeleteSummary = async (summaryId) => {
    try {
      await axios.delete(`${API_URL}/summaries/${summaryId}`, {
        headers: {
          "API-Key": API_KEY,
        },
      });
      setSummaries(summaries.filter((summary) => summary.id !== summaryId)); // Update state
      alert("Summary deleted successfully.");
    } catch (error) {
      console.error("Error deleting summary:", error);
      alert("Failed to delete summary.");
    }
  };

  return (
    <div className="summary-list-container">
      <h3>Summary List</h3>
      <button className="add-summary-button" onClick={handleAddSummary}>
        Add Summary
      </button>
      {summaries.length > 0 ? (
        <table className="summary-table">
          <thead>
            <tr>
              <th>Summary Name</th>
              <th>Link</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {summaries.map((summary) => (
              <tr key={summary.id}>
                <td>{summary.summaryName}</td>
                <td>
                  <a
                    href={summary.summaryLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Open Summary
                  </a>
                </td>
                <td>
                  <button onClick={() => handleUpdateSummary(summary.id)}>
                    Update
                  </button>
                  <button onClick={() => handleDeleteSummary(summary.id)}>
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No summaries available.</p>
      )}
    </div>
  );
};

export default SummaryList;
