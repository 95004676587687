import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const UpdateStudent = ({ inbox, bills, media }) => {
  const { id, adminId } = useParams(); // Extract student ID and admin ID from URL params
  // Get API URL and API Key from environment variables
  const API_URL = process.env.REACT_APP_API_URL;
  const API_KEY = process.env.REACT_APP_API_KEY;
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    password: "",
    role: "student",
    email: "",
    phoneNumber: "",
    gender: "",
    age: "",
    city: "",
    levelName: "",
    gradeName: "",
    subjectNames: [],
    dateOfJoin: "",
    inbox: inbox || "",
    bills: bills || "",
    media: media || "",
    groupStudentsNumber: [""],
  });

  const [error, setError] = useState("");
  const [levels, setLevels] = useState([]);
  const [grades, setGrades] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const levelResponse = await axios.get(`${API_URL}/levels`, {
          headers: {
            "API-Key": API_KEY, // Include API Key in the headers
          },
        });
        const gradeResponse = await axios.get(`${API_URL}/grades`, {
          headers: {
            "API-Key": API_KEY, // Include API Key in the headers
          },
        });
        const subjectResponse = await axios.get(`${API_URL}/subjects`, {
          headers: {
            "API-Key": API_KEY, // Include API Key in the headers
          },
        });

        setLevels(levelResponse.data);
        setGrades(gradeResponse.data);
        setSubjects(subjectResponse.data);
      } catch (error) {
        setError("Failed to load options. Please try again.");
      }
    };

    const fetchStudentData = async () => {
      try {
        const response = await axios.get(`${API_URL}/students/${id}`, {
          headers: {
            "API-Key": API_KEY, // Include API Key in the headers
          },
        });
        setFormData((prevData) => ({
          ...prevData,
          ...response.data,
          inbox: response.data.inbox || "", // Ensure inbox is populated from the API
          bills: response.data.bills || "", // Ensure bills is populated from the API
          media: response.data.media || "",
          groupStudentsNumber: Array.isArray(response.data.groupStudentsNumber)
            ? response.data.groupStudentsNumber
            : [response.data.groupStudentsNumber],
        }));
      } catch (error) {
        setError("Failed to fetch student data. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchOptions();
    fetchStudentData();
  }, [id]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setFormData((prevData) => {
        const subjectNames = checked
          ? [...prevData.subjectNames, value]
          : prevData.subjectNames.filter((subject) => subject !== value);
        return { ...prevData, subjectNames };
      });
    } else if (name.startsWith("groupStudentsNumber")) {
      const index = parseInt(name.split("-")[1]);
      const newGroupStudentsNumber = [...formData.groupStudentsNumber];
      newGroupStudentsNumber[index] = value;
      setFormData({ ...formData, groupStudentsNumber: newGroupStudentsNumber });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleAddGroupStudentNumber = () => {
    setFormData((prevData) => ({
      ...prevData,
      groupStudentsNumber: [...prevData.groupStudentsNumber, ""],
    }));
  };

  const handleRemoveGroupStudentNumber = () => {
    if (formData.groupStudentsNumber.length > 1) {
      setFormData((prevData) => ({
        ...prevData,
        groupStudentsNumber: prevData.groupStudentsNumber.slice(0, -1),
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !formData.firstName ||
      !formData.lastName ||
      !formData.password ||
      !formData.email ||
      !formData.phoneNumber ||
      !formData.gender ||
      !formData.age ||
      !formData.city ||
      !formData.levelName ||
      !formData.gradeName ||
      formData.subjectNames.length === 0 ||
      !formData.dateOfJoin ||
      formData.groupStudentsNumber.some((num) => num === "")
    ) {
      setError("Please fill in all required fields.");
      return;
    }

    try {
      await axios.put(`${API_URL}/students/${id}`, formData, {
        headers: {
          "Content-Type": "application/json",
          "API-Key": API_KEY, // Pass the API Key in the headers
        },
      });
      navigate(`/student-list/${adminId}`); // Navigate to student list with adminId
    } catch (error) {
      console.error("Error updating student data:", error);
      if (error.response) {
        setError(
          error.response.data.message ||
            "Failed to update student data. Please try again."
        );
      } else {
        setError("Failed to update student data. Please try again.");
      }
    }
  };

  const handleCancel = () => {
    navigate(`/student-list/${adminId}`); // Navigate to student list on cancel
  };

  if (isLoading) {
    return <p>Loading...</p>;
  }

  return (
    <div style={styles.container}>
      <h2>Update Student</h2>
      {error && <p style={styles.error}>{error}</p>}
      <form onSubmit={handleSubmit} style={styles.form}>
        {/* Form Fields */}
        <div style={styles.formGroup}>
          <label style={styles.label}>First Name</label>
          <input
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            required
            style={styles.input}
          />
        </div>

        <div style={styles.formGroup}>
          <label style={styles.label}>Last Name</label>
          <input
            type="text"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            required
            style={styles.input}
          />
        </div>

        <div style={styles.formGroup}>
          <label style={styles.label}>Password</label>
          <input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            required
            style={styles.input}
          />
        </div>

        <div style={styles.formGroup}>
          <label style={styles.label}>Email</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            style={styles.input}
          />
        </div>

        <div style={styles.formGroup}>
          <label style={styles.label}>Phone Number</label>
          <input
            type="text"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
            required
            style={styles.input}
          />
        </div>

        <div style={styles.formGroup}>
          <label style={styles.label}>Gender</label>
          <select
            name="gender"
            value={formData.gender}
            onChange={handleChange}
            required
            style={styles.select}
          >
            <option value="">Select Gender</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </select>
        </div>

        <div style={styles.formGroup}>
          <label style={styles.label}>Age</label>
          <input
            type="number"
            name="age"
            value={formData.age}
            onChange={handleChange}
            required
            min="1"
            style={styles.input}
          />
        </div>

        <div style={styles.formGroup}>
          <label style={styles.label}>City</label>
          <input
            type="text"
            name="city"
            value={formData.city}
            onChange={handleChange}
            required
            style={styles.input}
          />
        </div>

        <div style={styles.formGroup}>
          <label style={styles.label}>Level Name</label>
          <select
            name="levelName"
            value={formData.levelName}
            onChange={handleChange}
            required
            style={styles.select}
          >
            <option value="">Select Level</option>
            {levels.map((level) => (
              <option key={level.id} value={level.name}>
                {level.name}
              </option>
            ))}
          </select>
        </div>

        <div style={styles.formGroup}>
          <label style={styles.label}>Grade Name</label>
          <select
            name="gradeName"
            value={formData.gradeName}
            onChange={handleChange}
            required
            style={styles.select}
          >
            <option value="">Select Grade</option>
            {grades.map((grade) => (
              <option key={grade.id} value={grade.name}>
                {grade.name}
              </option>
            ))}
          </select>
        </div>

        <div style={styles.formGroup}>
          <label style={styles.label}>Subjects</label>
          {subjects.map((subject) => (
            <div key={subject.id}>
              <input
                type="checkbox"
                name="subjectNames"
                value={subject.name}
                checked={formData.subjectNames.includes(subject.name)}
                onChange={handleChange}
              />
              <label style={{ marginLeft: "5px" }}>{subject.name}</label>
            </div>
          ))}
        </div>

        <div style={styles.formGroup}>
          <label style={styles.label}>Date of Join</label>
          <input
            type="date"
            name="dateOfJoin"
            value={formData.dateOfJoin}
            onChange={handleChange}
            required
            style={styles.input}
          />
        </div>

        {/* Bills */}
        <div style={styles.formGroup}>
          <label style={styles.label}>Bills</label>
          <input
            type="text"
            name="bills"
            value={formData.bills}
            onChange={handleChange}
            style={styles.input}
          />
        </div>

        {/* Inbox */}
        <div style={styles.formGroup}>
          <label style={styles.label}>Inbox</label>
          <input
            type="text"
            name="inbox"
            value={formData.inbox}
            onChange={handleChange}
            style={styles.input}
          />
        </div>
        {/* social media */}
        <div style={styles.formGroup}>
          <label style={styles.label}>Teacher social media</label>
          <input
            type="text"
            name="media"
            value={formData.media}
            onChange={handleChange}
            style={styles.input}
          />
        </div>

        <div style={styles.formGroup}>
          <label style={styles.label}>Group Students Number</label>
          {formData.groupStudentsNumber.map((number, index) => (
            <input
              key={index}
              type="number"
              name={`groupStudentsNumber-${index}`}
              value={number}
              onChange={handleChange}
              required
              min="0"
              style={styles.input}
            />
          ))}
          <button
            type="button"
            onClick={handleAddGroupStudentNumber}
            style={styles.addButton}
          >
            Add Another Group Number
          </button>
          {formData.groupStudentsNumber.length > 1 && (
            <button
              type="button"
              onClick={handleRemoveGroupStudentNumber}
              style={styles.cancelButton}
            >
              Cancel Last Group Number
            </button>
          )}
        </div>

        {/* Submit Button */}
        <button type="submit" style={styles.button}>
          Update Student
        </button>

        {/* Cancel Button */}
        <button
          type="button"
          onClick={handleCancel}
          style={styles.cancelButton}
        >
          Cancel
        </button>
      </form>
    </div>
  );
};

// Styles remain unchanged
const styles = {
  container: {
    maxWidth: "600px",
    margin: "auto",
    padding: "20px",
    border: "1px solid #ccc",
    borderRadius: "5px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  formGroup: {
    marginBottom: "15px",
  },
  label: {
    marginBottom: "5px",
    fontWeight: "bold",
  },
  input: {
    padding: "10px",
    fontSize: "16px",
    border: "1px solid #ccc",
    borderRadius: "4px",
  },
  select: {
    padding: "10px",
    fontSize: "16px",
    border: "1px solid #ccc",
    borderRadius: "4px",
  },
  button: {
    padding: "10px",
    fontSize: "16px",
    backgroundColor: "#007BFF",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
  addButton: {
    padding: "5px",
    marginTop: "5px",
    backgroundColor: "#28A745",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
  cancelButton: {
    padding: "5px",
    marginTop: "5px",
    backgroundColor: "#dc3545",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
  error: {
    color: "red",
  },
};

export default UpdateStudent;
