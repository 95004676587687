import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const UpdateAdmin = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [adminData, setAdminData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    role: "",
  });
  const [error, setError] = useState("");

  // API configuration
  const API_URL = process.env.REACT_APP_API_URL;
  const API_KEY = process.env.REACT_APP_API_KEY;

  useEffect(() => {
    const fetchAdminData = async () => {
      try {
        // Adding the API key in the request headers
        const headers = {
          "API-Key": API_KEY, // Include the API key in the request headers
        };
        const response = await axios.get(`${API_URL}/admins/${id}`, {
          headers,
        });
        setAdminData(response.data); // Set the fetched admin data to state
      } catch (error) {
        setError("Failed to load admin data. Please try again.");
      }
    };

    fetchAdminData();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAdminData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Adding the API key in the request headers for the update
      const headers = {
        "API-Key": API_KEY, // Include the API key in the request headers
      };

      // Sending the PUT request to update the admin
      await axios.put(`${API_URL}/admins/${id}`, adminData, { headers });

      // Redirect to the admin list page after successful update
      navigate("/admin-list");
    } catch (error) {
      setError("Failed to update admin. Please try again.");
    }
  };

  return (
    <div className="update-admin-container">
      <h2>Update Admin</h2>
      {error && <p className="error-message">{error}</p>}
      <form onSubmit={handleSubmit}>
        <div>
          <label>First Name:</label>
          <input
            type="text"
            name="firstName"
            value={adminData.firstName}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Last Name:</label>
          <input
            type="text"
            name="lastName"
            value={adminData.lastName}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Email:</label>
          <input
            type="email"
            name="email"
            value={adminData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Password:</label>
          <input
            type="password"
            name="password"
            value={adminData.password}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Role:</label>
          <input
            type="text"
            name="role"
            value={adminData.role}
            onChange={handleChange}
            required
          />
        </div>
        <button type="submit">Update Admin</button>
      </form>
    </div>
  );
};

export default UpdateAdmin;
