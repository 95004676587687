// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button1 {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
  margin: 5px;
}

button:hover {
  background-color: #0056b3;
}

.error {
  color: red;
  font-weight: bold;
}

aside {
  background-color: #f8f9fa;
  padding: 15px;
  border-right: 1px solid #ccc;
  width: 250px;
  box-sizing: border-box;
}

main {
  flex: 1 1;
  padding: 15px;
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.table th,
.table td {
  padding: 10px;
  text-align: left;
  border: 1px solid #ccc;
}

.table th {
  background-color: #007bff;
  color: white;
}

.table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.table td button {
  margin-right: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/styles/student/StudentList.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,iBAAiB;EACjB,eAAe;EACf,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,UAAU;EACV,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;EACzB,aAAa;EACb,4BAA4B;EAC5B,YAAY;EACZ,sBAAsB;AACxB;;AAEA;EACE,SAAO;EACP,aAAa;AACf;;AAEA;EACE,WAAW;EACX,yBAAyB;EACzB,gBAAgB;AAClB;;AAEA;;EAEE,aAAa;EACb,gBAAgB;EAChB,sBAAsB;AACxB;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".button1 {\n  background-color: #007bff;\n  color: white;\n  border: none;\n  padding: 5px 10px;\n  cursor: pointer;\n  border-radius: 5px;\n  margin: 5px;\n}\n\nbutton:hover {\n  background-color: #0056b3;\n}\n\n.error {\n  color: red;\n  font-weight: bold;\n}\n\naside {\n  background-color: #f8f9fa;\n  padding: 15px;\n  border-right: 1px solid #ccc;\n  width: 250px;\n  box-sizing: border-box;\n}\n\nmain {\n  flex: 1;\n  padding: 15px;\n}\n\n.table {\n  width: 100%;\n  border-collapse: collapse;\n  margin-top: 20px;\n}\n\n.table th,\n.table td {\n  padding: 10px;\n  text-align: left;\n  border: 1px solid #ccc;\n}\n\n.table th {\n  background-color: #007bff;\n  color: white;\n}\n\n.table tr:nth-child(even) {\n  background-color: #f2f2f2;\n}\n\n.table td button {\n  margin-right: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
