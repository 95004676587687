import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../styles/student/login.css"; // Make sure this path is correct
import AuthService from "./AuthService"; // Assuming AuthService is imported correctly

const TeacherLoginForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(""); // General error message
  const [emailError, setEmailError] = useState(false); // Track email error state
  const [passwordError, setPasswordError] = useState(false); // Track password error state
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setEmailError(false); // Reset email error
    setPasswordError(false); // Reset password error

    try {
      const response = await AuthService.authenticateTeacher({
        email,
        password,
      });
      // If authentication is successful, store the teacher data in localStorage
      AuthService.setCurrentUser(response.data);

      // Navigate to the teacher dashboard
      navigate("/teacher-dashboard");
    } catch (error) {
      const errorMessage =
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : error.message || "Unknown error occurred"; // Default fallback message

      setError(errorMessage); // Display the general error message

      // Check for specific error messages and set respective error states
      if (errorMessage.toLowerCase().includes("email")) {
        setEmailError(true); // Show error for email field
      } else if (errorMessage.toLowerCase().includes("password")) {
        setPasswordError(true); // Show error for password field
      }
    }
  };

  return (
    <div className="login">
      <div className="login-container">
        <h2 className="login-heading">Teacher Login</h2>

        {/* Display general error message */}
        {error && <p className="error">{error}</p>}

        <form onSubmit={handleSubmit} className="login-form">
          {/* Email Field */}
          <div className="input-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              placeholder="Enter your email"
              style={{
                borderColor: emailError ? "red" : "#ccc",
                backgroundColor: emailError ? "#fdd" : "#fff",
              }}
            />
            {emailError && (
              <small className="error-text">Invalid email address</small>
            )}
          </div>

          {/* Password Field */}
          <div className="input-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              placeholder="Enter your password"
              style={{
                borderColor: passwordError ? "red" : "#ccc",
                backgroundColor: passwordError ? "#fdd" : "#fff",
              }}
            />
            {passwordError && (
              <small className="error-text">Invalid password</small>
            )}
          </div>

          {/* Submit Button */}
          <div className="buttonContainer">
            <button
              type="submit"
              style={{
                width: "100%",
                padding: "10px",
                fontSize: "16px",
                borderRadius: "8px",
                color: "white",
                backgroundColor: "#007bff",
                border: "none",
                cursor: "pointer",
              }}
            >
              Login
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default TeacherLoginForm;
