import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const validUsers = [
    { email: "test@gmail.com", password: "password", role: "admin" },
    { email: "user@example.com", password: "user123", role: "user" },
  ];

  const handleLogin = (e) => {
    e.preventDefault();

    // Validate user credentials
    const user = validUsers.find(
      (u) => u.email === email && u.password === password
    );

    if (user) {
      localStorage.setItem("authToken", JSON.stringify(user)); // Save user data
      navigate("/admin-list"); // Navigate to Admin-List
    } else {
      setError("Invalid email or password");
    }
  };

  return (
    <div className="login">
      <div className="login-container">
        <h2>Login</h2>
        {error && <p className="error-message">{error}</p>}
        <form onSubmit={handleLogin}>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <button type="submit">Login</button>
        </form>
      </div>{" "}
    </div>
  );
};

export default Login;
