import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // useNavigate in React Router v6

const CreateLevel = () => {
  const [name, setName] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate(); // useNavigate hook for navigation

  const API_URL = process.env.REACT_APP_API_URL; // API URL from environment variables
  const API_KEY = process.env.REACT_APP_API_KEY; // API Key from environment variables

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!name) {
      setError("Level name is required");
      return;
    }

    try {
      await axios.post(
        `${API_URL}/levels`, // Use the API_URL for the API call
        { name },
        {
          headers: {
            "API-Key": API_KEY, // Include API-Key in headers
          },
        }
      );
      navigate("/admin-list"); // Redirect after successful creation
    } catch (error) {
      setError("Failed to create level. Please try again.");
    }
  };

  return (
    <div className="create-level-container">
      <h2>Create New Level</h2>
      {error && <p className="error-message">{error}</p>}
      <form onSubmit={handleSubmit}>
        <div>
          <label>Level Name:</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="submit-button">
          Create Level
        </button>
      </form>
    </div>
  );
};

export default CreateLevel;
