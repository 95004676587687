import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const StudentDetails = () => {
  const { id } = useParams(); // Get student ID from route params
  const [student, setStudent] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);

  // Get API URL and API Key from environment variables
  const API_URL = process.env.REACT_APP_API_URL;
  const API_KEY = process.env.REACT_APP_API_KEY;

  useEffect(() => {
    fetchStudentDetails();
  }, [id]);

  const fetchStudentDetails = async () => {
    try {
      const response = await axios.get(`${API_URL}/students/${id}`, {
        headers: {
          "API-Key": API_KEY, // Pass the API Key in headers
        },
      });

      setStudent(response.data); // Set the student data
    } catch (err) {
      console.error("Error fetching student details:", err);
      setError("Failed to fetch student details. Please try again.");
    } finally {
      setLoading(false); // Set loading to false regardless of success or failure
    }
  };

  // Loading state
  if (loading) {
    return <p>Loading...</p>;
  }

  // Error state
  if (error) {
    return <p className="error">{error}</p>;
  }

  // No data state
  if (!student) {
    return <p>No student details available.</p>;
  }

  return (
    <div style={{ padding: "25px" }}>
      <h2>Student Details</h2>
      <div>
        <p>
          <strong>ID:</strong> {student.id}
        </p>
        <p>
          <strong>First Name:</strong> {student.firstName}
        </p>
        <p>
          <strong>Last Name:</strong> {student.lastName}
        </p>
        <p>
          <strong>Phone Number:</strong> {student.phoneNumber}
        </p>
        <p>
          <strong>Email:</strong> {student.email}
        </p>
        <p>
          <strong>Password:</strong> {student.password}
        </p>
        <p>
          <strong>Gender:</strong> {student.gender}
        </p>
        <p>
          <strong>Age:</strong> {student.age}
        </p>
        <p>
          <strong>State:</strong> {student.state}
        </p>
        <p>
          <strong>City:</strong> {student.city}
        </p>
        <p>
          <strong>Level Name:</strong> {student.levelName}
        </p>
        <p>
          <strong>Grade Name:</strong> {student.gradeName}
        </p>
        <p>
          <strong>Group Students Number:</strong>{" "}
          {Array.isArray(student.groupStudentsNumber)
            ? student.groupStudentsNumber.join(", ")
            : student.groupStudentsNumber}
        </p>
        <p>
          <strong>Subject Names:</strong>{" "}
          {Array.isArray(student.subjectNames)
            ? student.subjectNames.join(", ")
            : student.subjectNames}
        </p>
        <p>
          <strong>Date of Join:</strong>{" "}
          {new Date(student.dateOfJoin).toLocaleDateString()}
        </p>
        <p>
          <strong>Role:</strong> {student.role}
        </p>
        <p>
          <strong>Bills:</strong> {student.bills}
        </p>
        <p>
          <strong>Inbox:</strong> {student.inbox}
        </p>
        <p>
          <strong>Teacher Social Media:</strong>{" "}
          {student.media && /^https?:\/\//.test(student.media) ? (
            <a href={student.media} target="_blank" rel="noopener noreferrer">
              {student.media}
            </a>
          ) : (
            <span>{student.media}</span>
          )}
        </p>
      </div>
    </div>
  );
};

export default StudentDetails;
