import axios from "axios";

// API configuration
const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;

const AuthService = {
  // Authenticate Student
  authenticateStudent: async (credentials) => {
    try {
      const headers = {
        "API-Key": API_KEY, // Use API key from environment variable
      };

      const response = await axios.post(
        `${API_URL}/students/authenticate`,
        null,
        {
          params: credentials,
          headers: headers, // Add the API key in headers
        }
      );

      AuthService.setCurrentUser(response.data, "student"); // Store user type
      return response;
    } catch (error) {
      throw error;
    }
  },

  // Authenticate Admin
  authenticateAdmin: async (credentials) => {
    try {
      const headers = {
        "API-Key": API_KEY, // Use API key from environment variable
      };

      const response = await axios.post(
        `${API_URL}/admins/authenticate`,
        null,
        {
          params: credentials,
          headers: headers, // Add the API key in headers
        }
      );

      AuthService.setCurrentUser(response.data, "admin"); // Store user type
      return response;
    } catch (error) {
      throw error;
    }
  },

  // Authenticate Teacher
  authenticateTeacher: async (credentials) => {
    try {
      const headers = {
        "API-Key": API_KEY, // Use API key from environment variable
      };

      const response = await axios.post(
        `${API_URL}/teachers/authenticate`,
        null,
        {
          params: credentials,
          headers: headers, // Add the API key in headers
        }
      );

      AuthService.setCurrentUser(response.data, "teacher"); // Store user type
      return response;
    } catch (error) {
      throw error;
    }
  },

  // Logout the user
  logout: () => {
    // Remove current user from localStorage
    localStorage.removeItem("currentUser");
  },

  setCurrentUser: (user, userType) => {
    // Store user data along with user type
    localStorage.setItem(
      "currentUser",
      JSON.stringify({ ...user, type: userType })
    );
  },

  getCurrentUser: () => {
    return JSON.parse(localStorage.getItem("currentUser"));
  },
};

export default AuthService;
